import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import Header from "./Header";
import Banner from "./Banner";
import Feature from "./Feature";
import FAQ from "./FAQ";
import Footer from "./Footer";
import "./static/style";
import chLogo from "../assets/imgs/chili/chLogo.png";
import phLogo from "../assets/imgs/chili/phLogo.png";
let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
    showLocalContry: false,
    localCountry: { logo: phLogo, name: "Philippine", key: "ph" },
    localCountrys: [
      { logo: phLogo, name: "Philippine", key: "ph" },
      { logo: chLogo, name: "Chile", key: "cl" },
    ],
  };

  componentDidMount() {
    const { localCountrys } = this.state;
    const query = new URLSearchParams(window.location.search);
    if (query) {
      let cty = query.get("cty");
      if (cty) {
        let localCountry = this.state.localCountry;
        localCountrys.forEach((item) => {
          if (item.key === cty) {
            localCountry = item;
          }
        });
        this.setState({ localCountry });
      }
    }

    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener("scroll", this.bindScroll);
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    const producteOT =
      document.getElementById("product").offsetTop - window.innerHeight / 2;
    const faqOT =
      document.getElementById("FAQ").offsetTop - window.innerHeight / 2;
    if (scrollTop < producteOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: false,
      });
    } else if (producteOT < scrollTop && scrollTop < faqOT) {
      this.setState({
        activeHome: false,
        activeProduct: true,
        activefaq: false,
      });
    } else if (scrollTop > faqOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: true,
      });
    }
  };
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === "leave" });
  };
  jumpToSection = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // 64 header高度
      window.scrollTo(0, element.offsetTop - 64);
    }
  };
  handleLocalContrys = () => {
    const { showLocalContry } = this.state;
    this.setState({ showLocalContry: !showLocalContry });
  };
  handleChangeContry = (item) => {
    // window.location;
    const query = new URLSearchParams(window.location.search);
    if (query.get("cty")) {
      query.set("cty", item.key);
    } else {
      query.append("cty", item.key);
    }

    window.location.search = "?" + query.toString();
    this.setState({ localCountry: item, showLocalContry: false });
  };

  render() {
    const renderInfo = () => {
      return [
        <Header
          key="header"
          isScroll={this.state.isScroll}
          activeHome={this.state.activeHome}
          activeProduct={this.state.activeProduct}
          activefaq={this.state.activefaq}
          handleclick={this.jumpToSection}
          showLocalContry={this.state.showLocalContry}
          localCountry={this.state.localCountry}
          handleLocalContrys={this.handleLocalContrys}
          localCountrys={this.state.localCountrys}
          handleChangeContry={this.handleChangeContry}
          isBgWhite={this.state.isBgWhite}
          className={this.state.isBgWhite ? "btn-white" : ""}
        />,
        <Banner
          key="banner"
          contryKey={this.state.localCountry.key}
          isMobile={this.state.isMobile}
          navToShadow={this.navToShadow}
        />,
        <Feature
          key="Feature"
          contryKey={this.state.localCountry.key}
          isMobile={this.state.isMobile}
        />,
        <FAQ
          key="FAQ"
          contryKey={this.state.localCountry.key}
          isMobile={this.state.isMobile}
        />,
        <Footer
          key="footer"
          contryKey={this.state.localCountry.key}
          isMobile={this.state.isMobile}
        />,
        <DocumentTitle title="WePeso" />,
      ];
    };
    return <div>{renderInfo()}</div>;
  }
}
export default Home;
