import featureIcon1 from "../assets/imgs/featureIcon1.png";
import featureIcon2 from "../assets/imgs/featureIcon2.png";
import featureIcon3 from "../assets/imgs/featureIcon3.png";
import clFeatureIcon3 from "../assets/imgs/chili/featureIcon3.png";

import faq1Img from "../assets/imgs/faqIcon1.png";
import faq2Img from "../assets/imgs/faqIcon2.png";
import faq3Img from "../assets/imgs/faqIcon3.png";

export const page1 = {
  ph: [
    {
      img: featureIcon1,
      icon: "#iconzu5",
      title: "Seamless Step For Application",
      description: "Simple imformation with 1 legal ID",
    },
    {
      img: featureIcon2,
      icon: "#iconzu2",
      title: "Fast Audit Check",
      description: "A pleasant experience you never have",
    },
    {
      img: featureIcon3,
      icon: "#iconzu4",
      title: "Rapid Disbursement",
      description: "Disbursed as soon as possible once you get approved",
    },
  ],
  cl: [
    {
      img: featureIcon1,
      icon: "#iconzu5",
      title: "Paso perfecto para la aplicación",
      description: "Información simple con 1 identificación legal",
    },
    {
      img: featureIcon2,
      icon: "#iconzu2",
      title: "Verificación de auditoría rápida",
      description: "Una experiencia placentera que nunca has tenido.",
    },
    {
      img: clFeatureIcon3,
      icon: "#iconzu4",
      title: "Desembolso rápido",
      description:
        "Desembolsado lo antes posible una vez que obtenga la aprobación",
    },
  ],
};

export const page2 = {
  ph: [
    {
      img: faq1Img,
      title: "What Qualifications Are Required？",
      descriptions: [
        "1) A Phone number in use",
        "2) Valid government ID",
        "3) Stable income",
        "4) Age between 18-49 years old",
      ],
    },
    {
      img: faq2Img,
      title: "How To Apply A Loan? ",
      descriptions: [
        "1) Install WePeso and register with your own phone number.",
        "2) Fill out peso loan application form.",
        "3) Wait for cash loan approval.",
        "4) Claim your personal loan.",
      ],
    },
    {
      img: faq3Img,
      title: "How To Repay Your Cash Loan?",
      descriptions: [
        "1)M Lhuillier:Visit M Lhuillier and fill the repay form.",
        "2)7-Eleven: Go to 7-Eleven and repay via CLiQQ machine or CLiQQ app.",
        "3)GCash,Coins: Open app, click Pay Bills to access Loans option to repay.",
        "4)Bank: Deposit money to our corporate bank account given in the app.",
        "5)ECPAY: Go to ECPAY, fill out needed information in Bills Payment Form, reimburse your personal loans and receive a payment confirmation receipt.",
      ],
    },
  ],
  cl: [
    {
      img: faq1Img,
      title: "¿Qué calificaciones se requieren?",
      descriptions: [
        "1) Un número de teléfono en uso",
        "2) Valid government ID",
        "3) Identificación gubernamental válida",
        "4) Edad entre 18-49 años",
      ],
    },
    {
      img: faq2Img,
      title: "¿Cómo solicitar un préstamo?",
      descriptions: [
        "1) Instala WePeso y regístrate con tu propio número de teléfono.",
        "2) Llene el formulario de solicitud de préstamo en pesos.",
        "3) Espere la aprobación del préstamo en efectivo.",
        "4) Reclama tu préstamo personal.",
      ],
    },
    {
      img: faq3Img,
      title: "¿Cómo pagar su préstamo en efectivo?",
      descriptions: [
        "Deposite dinero en nuestra cuenta bancaria corporativa que figura en la aplicación.",
      ],
    },
  ],
};
