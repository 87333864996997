import React from "react";
import PropTypes from "prop-types";
import { Menu, Button, Popover } from "antd";

import { enquireScreen } from "enquire-js";
import websiteLogo from "../assets/imgs/website-logo.png";
import websiteLogo2 from "../assets/imgs/website-logo2.png";

import moreImg from "../assets/imgs/chili/more.png";

class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
    isBgWhite: PropTypes.bool,
    localCountry: PropTypes.object,
    localCountrys: PropTypes.array,
    handleChangeContry: PropTypes.func,
  };

  state = {
    menuVisible: false,
    menuMode: "horizontal",
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? "inline" : "horizontal" });
    });
  }

  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible });
  };

  handleMenuClick = (event) => {
    this.props.handleclick(event.key);
    this.setState({ menuVisible: false });
  };

  render() {
    const {
      activefaq,
      activeProduct,
      activeHome,
      handleclick,
      isBgWhite = false,
      showLocalContry,
      localCountry,
      localCountrys,
    } = this.props;
    const { menuMode, menuVisible } = this.state;
    const isChili = localCountry.key === "cl";
    const homeStr = isChili ? "Casa" : "Home";
    const productStr = isChili ? "Productos" : "Product";
    const FAQStr = isChili ? "FAQ" : "FAQ";
    const menu = (
      <Menu
        mode="inline"
        id="nav"
        key="nav"
        onClick={(e) => this.handleMenuClick(e)}
      >
        <Menu.Item key="home">
          <span>{homeStr}</span>
        </Menu.Item>
        <Menu.Item key="product">
          <span>{productStr}</span>
        </Menu.Item>
        <Menu.Item key="FAQ">
          <span>{FAQStr}</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <header {...this.props}>
        <div
          className={[
            "headerWrapper",
            menuMode !== "horizontal" && "headerWrapperMobile",
          ].join(" ")}
        >
          <div className="headerLeft">
            <img
              src={isBgWhite ? websiteLogo2 : websiteLogo}
              className="logo"
              alt="logo"
            />
            <div
              className={["localCountry", isBgWhite && "localCountryBg"].join(
                " "
              )}
              onClick={() => {
                this.props.handleLocalContrys();
              }}
            >
              <img
                src={localCountry.logo}
                className="countrylogo"
                alt="localCountry"
              />
              <span className="countryName">{localCountry.name}</span>
              <div className="countryMore">
                <img src={moreImg} className="moreImg" alt="moreImg" />
              </div>

              {showLocalContry && (
                <div className="countrySelect">
                  {localCountrys.map((item) => (
                    <div
                      className={[
                        "selet",
                        item.key === localCountry.key && "seletOn",
                      ].join(" ")}
                      onClick={() => {
                        this.props.handleChangeContry(item);
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {menuMode === "horizontal" ? (
            <div>
              <div
                className={activefaq ? "active-btn" : "button"}
                onClick={() => handleclick("FAQ")}
              >
                <Button>{FAQStr}</Button>
              </div>
              <div
                className={activeProduct ? "active-btn" : "button"}
                onClick={() => handleclick("product")}
              >
                <Button>{productStr}</Button>
              </div>
              <div
                className={activeHome ? "active-btn" : "button"}
                onClick={() => handleclick("home")}
              >
                <Button>{homeStr}</Button>
              </div>
            </div>
          ) : (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.handleShowMenu}
            >
              <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
                <use xlinkHref="#iconmenu1" />
              </svg>
            </Popover>
          )}
        </div>
      </header>
    );
  }
}

export default Header;
