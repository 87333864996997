import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import chLogo from "../assets/imgs/chili/chLogo.png";
import phLogo from "../assets/imgs/chili/phLogo.png";
import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
    localCountryKey: null,
    // isScroll: false,
    localCountrys: [
      { logo: phLogo, name: "Philippine", key: "ph" },
      { logo: chLogo, name: "Chile", key: "cl" },
    ],
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("cty")) {
      let cty = query.get("cty");
      this.setState({ localCountryKey: cty });
    }
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    const { localCountryKey, localCountrys } = this.state;
    return localCountryKey === null ? (
      <div className={"contentWrapper"}>
        <div className={"select"}>
          <div className="countrySelect">
            {localCountrys.map((item) => (
              <div
                className={[
                  "localCountry",
                  item.key === localCountryKey && "seletOn",
                ].join(" ")}
                onClick={() => {
                  this.setState({ localCountryKey: item.key });
                }}
              >
                <img
                  src={item.logo}
                  className="countrylogo"
                  alt="localCountry"
                />
                <span className="countryName">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : localCountryKey === "ph" ? (
      <div className={"contentWrapper"}>
        <DocumentTitle title="WePeso Customer Service Policy" />
        <h2> WePeso TERMS OF SERVICE</h2>
        <p />
        <p>
          {" "}
          By using, accessing, downloading, browsing or registering to the
          WePeso service on the WePeso website, the WePeso mobile application
          (the “WePeso Application”), or other related digital medium or media
          including social media (each and collectively the WePeso “Facility”),
          you signify that you have read, understood, and accepted these Terms
          of Service and agree to be bound by them, including all related rules
          and regulations for the use and availment of any service, product or
          information of the WePeso Facility, as the same is currently available
          or as may be made available in the future. You likewise agree to be
          bound by any and all laws, rules, regulations and governmental
          issuances applicable to the WePeso Facility or the Company (as
          hereinafter defined below), now existing or which may hereinafter be
          issued, as well as such other terms and conditions governing the use
          of other facilities, benefits, products or services which we may make
          available to you in connection with the WePeso service, such as but
          not limited to Member Get Member Programs, or other similar programs
          as may be offered in the future. You may be required to register an
          account (“WePeso Account”) to use the WePeso service. If you access
          the WePeso service via any WePeso Facility whether via the WePeso
          Application, upon your access of the WePeso Facility to use the WePeso
          service, these Terms of Service shall be a binding agreement between
          you and Oriente Express Techsystem Corporation (“OETC” or the
          “Company”). If you do not agree, do not fully understand or have
          reservations with respect to any provision of these Terms of Service,
          please exit and stop your use of this WePeso Facility.
        </p>
        <p> Eligibility</p>
        <p />
        <p>
          {" "}
          The WePeso Facility is intended solely for the use of an individual at
          least eighteen (18) years of age, in good credit standing, and has the
          legal capacity to enter into binding agreements, specifically the
          availment of products and/or services offered in the WePeso Facility.
        </p>
        <p>
          {" "}
          By creating and maintaining a WePeso Account, including to use any
          product, service, facilities, or benefits in the WePeso Facility, you
          represent and warrant that you possess the foregoing eligibility
          requirements. You likewise expressly authorize, consent and permit the
          Company to use all necessary means to verify your identity, conduct
          fraud check, fraud validation, background checks, credit and
          background checks, based on the information you have provided, or by
          obtaining data from other sources including from any third-party
          providers of information. The use of the WePeso Facility is also
          governed by the WePeso Privacy Policy, incorporated herein by
          reference.
        </p>
        <p> Registration and Application</p>
        <p />
        <p>
          {" "}
          In creating your WePeso Account or applying to become a borrower or
          lender, you agree to provide the true, current, complete and accurate
          information about yourself in the registration or application form
          which is necessary for purposes of availing services under the WePeso
          Facility. The Company and its partner lenders reserve the right to
          conduct a background verification of your identity, including to
          conduct a credit and fraud investigation prior to, during and for the
          duration of your registration, application, use of the WePeso
          Facility, any loan, or any other service or feature. If any
          information you provide is untrue, inaccurate, not current, or
          incomplete, we reserve the right to cancel your registration, suspend
          your WePeso Account, reject any application you have submitted, and
          restrict your future use of the WePeso Facility and availment of
          products and services available over the WePeso Facility. The Company
          also reserves the right to reject any registration, deny access to the
          WePeso Facility and/or decline any loan application or investment in
          violation of these Terms of Service.
        </p>
        <p>
          {" "}
          Prior to registration and use of the WePeso Facility, it is important
          for you to know that the services can only be provided if you agree to
          disclose relevant identification, employment and financial information
          which you will be required to provide and may also be obtained by the
          Company, on your behalf, from all relevant sources in accordance with
          your instruction and authorization (the “Authorization”).
        </p>
        <p>
          {" "}
          By accepting and clicking on the “Agree” button below, you hereby
          acknowledge that you have read and accepted the Privacy Policy and
          agree that the collection, storage, processing (including for purposes
          of development of a credit score and user profiling) and disclosure of
          the information you have provided (including regarding nominated
          Related Party Contacts and/or Third Party Contacts or other reference
          persons) or will provide to third parties, such as but not limited to,
          a lender, borrower, third-party purchaser of a loan including
          delinquent loan, third-party data processors, credit bureau (including
          the Credit Information Corporation (CIC) and other submitting entities
          to whom the CIC may, as permitted by law share such data), credit
          organization, as is necessary and required for the access and use of
          the WePeso Facility and its services.
        </p>
        <p>
          {" "}
          Please read and confirm that you agree to the Authorization and
          Special Power of Attorney. If you do not agree or have reservations
          with respect to any provision of the Authorization and Special Power
          of Attorney, as to its scope, coverage or purpose, please exit the
          WePeso Facility and discontinue using the WePeso Service.
        </p>
        <p> General Privacy</p>
        <p />
        <p>
          {" "}
          In accessing and using the WePeso Facility, you agree that the
          following personal information (hereinafter referred to as “Personal
          Data”) may be collected, used and processed in accordance with the
          WePeso Privacy Policy:-
        </p>
        <p>
          {" "}
          Full name, permanent and residential address, contact number/s, email
          address, birth date and/or age, gender, employment information, bank
          account details, credit card and/or financial account information,
          financial history and details of government-issued ID;
        </p>
        <p>
          {" "}
          social media, e-commerce, transportation and other online/mobile
          account details and other information from which your identity may not
          be apparent or which may not reasonably and directly identify you,
          such as, but not limited to, records of your visits and information
          you submit when using the WePeso Facility;
        </p>
        <p>
          {" "}
          information from third parties including information obtained, upon
          your authorization, from any third party including your employers
          (“Employment Information), and other third-party source holding
          information on any financial, transaction, or payment records of the
          User, and historical transactions;
        </p>
        <p>
          {" "}
          traffic, usage, device, and other application information generated or
          found in your device and from your use of the WePeso Facility; and
        </p>
        <p>
          {" "}
          Anonymous, technical, network, and interaction data generated or
          available in the device or any other access medium used to access or
          make use of the WePeso Facility.
        </p>
        <p>
          {" "}
          The above information shall be obtained (from you or on your behalf
          from other sources) upon your registration in the WePeso Facility or
          upon your application to avail any of the WePeso Facility’s products
          and services shall be collected, used, processed, disclosed, retained,
          stored, and protected by the Company in accordance with the WePeso
          Privacy Policy and these WePeso Terms of Service:
        </p>
        <p>
          {" "}
          A general summary of how Personal Data is handled is set out as below.
          Please refer to the WePeso Privacy Policy for more detailed
          information regarding all collection, use, processing, sharing,
          storage and transfer of user data through the WePeso Facility
          including Personal Data.
        </p>
        <p>
          {" "}
          The Personal Data may be collected through the following means:
          cookies, flash cookies, general log in information, information
          obtained during WePeso Account registration, information from
          transactions transacted through the WePeso Account, information
          obtained from your employer, your device (subject to your permission
          settings), and referral information from third-party websites,
          sources, applications and offline sources.
        </p>
        <p> The Personal Data may be disclosed to the following:</p>
        <p>
          {" "}
          (a) the Company’s affiliates and subsidiaries, agents (including
          collection agencies), and subcontractors, which are necessary for the
          conduct of the Company’s business and on a need-to-know basis;
        </p>
        <p>
          {" "}
          (b) The lenders with respect to Personal Data of borrowers, and the
          borrowers with respect to Personal Data of the lenders;
        </p>
        <p>
          {" "}
          (c) third-party providers, including those who require the information
          to facilitate the loan, including but not limited to the payment,
          remittance, or processing of loan to the borrower, payments to the
          lender, transfer of the loan to a third-party (i.e. financing company,
          new lender, monetary authority);
        </p>
        <p>
          {" "}
          (d) the government, regulatory agencies, and fraud prevention agencies
          for the purposes of identifying, preventing, detecting, monitoring or
          tackling of suspected, or actual fraud, money laundering, or other
          crimes, and for other lawful purposes;
        </p>
        <p> (e) marketing with third-party partners; and</p>
        <p>
          {" "}
          (f) other entities as may be required by law or as public interest may
          warrant.
        </p>
        <p>
          {" "}
          In the event that your Personal Data is shared to any third-party for
          the purposes not described above, such sharing shall be subject to a
          data sharing agreement or an outsourcing agreement which shall
          require, among others, that such third-party is bound to exercise the
          same diligence in the protection of such Personal Data. You shall be
          notified by the Company of such sharing prior to any processing of
          your Personal Data, at the email address you provided upon
          registration. In the event that you wish to withhold your consent to
          any such processing or sharing, such request shall be deemed as a
          request for the deletion of your WePeso Account and the consequent
          termination of your transactions in accordance with the terms and
          conditions of the agreements you accepted and executed.
        </p>
        <p>
          {" "}
          The Company may use your Personal Data or other internet usage data as
          the Company may require in connection with the conduct of the
          Company’s business, such as, but not limited to:
        </p>
        <p> (i) identify you as user of the WePeso Facility;</p>
        <p>
          {" "}
          (ii) contact you in relation to your registered WePeso Account/s or
          requested information;
        </p>
        <p>
          {" "}
          (iii) processing registration of your WePeso Account/s as part of
          screening of the investors, borrowers, and loan applications;
        </p>
        <p> (iv) user profiling;</p>
        <p> (v) generation of a credit score;</p>
        <p> (vi) fraud monitoring, prevention and detection; and</p>
        <p> (vii) to maintain internal records.</p>
        <p>
          {" "}
          The Company shall retain your Personal Data for the duration of your
          activities and transactions in connection with the products and
          services availed; for the establishment, and for its due diligence,
          fraud prevention and detection program, background and credit checks,
          offer of new products and services, credit scoring and assessment,
          exercise or defense of a legal claim; for legitimate business
          purposes; or in instances required by law. Your Personal Data shall
          then be disposed in a secure manner that would prevent further
          processing, unauthorized access, or disclosure to any other party or
          the public, or prejudice your interest.
        </p>
        <p> User Content</p>
        <p />
        <p>
          {" "}
          By submitting content to the WePeso Facility, you expressly agree to
          the following:
        </p>
        <p>
          {" "}
          You retain all ownership rights to the content you have uploaded on
          the WePeso Facility.
        </p>
        <p>
          {" "}
          You hereby grant the Company a non-exclusive, transferable,
          sub-licensable, royalty-free, world license to use, reproduce,
          distribute, prepare derivative works of, display, and perform any
          information or content that you provide in connection with your use of
          the WePeso Facility and its services, subject to the privacy
          provisions in these WePeso Terms of Service and the WePeso Privacy
          Policy. You grant the Company the right to review, delete, edit,
          reformat, excerpt, or translate any of your information or content.
        </p>
        <p>
          {" "}
          You are solely responsible for the content and information you make
          available through or in connection with our products and services. The
          Company will not be liable for any use or misuse of your Personal Data
          by others.
        </p>
        <p>
          {" "}
          All the information and content posted on the WePeso Facility or
          privately transmitted through the WePeso Facility or via other means
          in connection with the WePeso Facility’s services is the sole
          responsibility of the person from whom that content originated. The
          Company will not be responsible for any errors in or omission of any
          information or content posted by a user.
        </p>
        <p>
          {" "}
          The Company may access and use the information recorded by credit
          reference and fraud prevention agencies for purposes of assessing
          lending risks and identifying, preventing, detecting or tackling
          fraud, money laundering and other crimes.
        </p>
        <p>
          {" "}
          You hereby expressly undertake the sole responsibility and hold the
          Company and the Oriente Group free of liability to you or any
          third-party, for any content that you create, upload, transmit, or
          display while using the WePeso service via the WePeso Facility. You
          also undertake not to share, create, upload, transmit or furnish using
          the WePeso Facility any material, information or content which is or
          may be covered by copyright, patent, trade secret, trademark,
          tradename, service mark or any property rights, including privacy
          and/or publicity rights, unless you have the necessary licences,
          rights, consents and permissions to use and to authorise the Company
          and WePeso to use any and/or all the content that you create, upload,
          transmit or furnish using the WePeso Facility.
        </p>
        <p>
          {" "}
          You undertake not to use fictitious name or make any concealment of
          true name for the purpose of concealing crime, evading the execution
          of a judgement or causing damage.
        </p>
        <p>
          {" "}
          You agree and hereby undertake sole responsibility for the
          consequences of your actions and interactions using the WePeso
          services, and hereby stipulate admission of liability to the Company
          and the Oriente Group for whatever loss or damage the Company or the
          Oriente Group may suffer as a consequence of your use or misuse of the
          WePeso services or access to the WePeso Facility.
        </p>
        <p> Third-Party Content & Services</p>
        <p />
        <p>
          {" "}
          The WePeso Facility may contain content provided by third-party
          services and resources. You acknowledge and agree that we are not
          responsible or liable for the availability or accuracy,
          appropriateness, completeness or non-infringement of third-party
          services, or the contents, products or services available on or
          through such third-party services. Unless specified by the Company in
          respect of its WePeso service via the WePeso Facility and/or otherwise
          notified to you, the availability of such third-party services does
          not imply any endorsement by us of such third-party services of the
          content, products or services available therefrom. The Company and the
          WePeso service makes no representation or warranties whatsoever about
          any service, content and/or other resources provided by the
          third-party. You may not copy, remove, alter, or obscure any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the products or services in respect of
          any content and/or other resources provided by the third-party.
        </p>
        <p>
          {" "}
          We assume no responsibility for the conduct of any third-party
          merchant submitting any content on their products and services or from
          their resources, and we assume no responsibility for monitoring the
          WePeso service for inappropriate contents or conduct. We do not, and
          cannot, pre-screen or monitor all user and partner content. Your use
          of the WePeso service and availment of the products and services made
          available via the WePeso Facility shall be at your own risk. By using
          the WePeso service, you may be exposed to content that may be not in
          line with your expectations. You bear all risks associated with your
          reliance on any available content in connection with the WePeso
          Service.
        </p>
        <p> No Warranty; Errors</p>
        <p />
        <p>
          {" "}
          The products and services available on the WePeso Facility, including
          products and services provided by third parties through the WePeso
          Facility are provided on “as is”, “as available” basis and without any
          representation or warranty of any kind. To the fullest extent
          permissible under applicable laws, the Company disclaims all such
          warranties, express or implied, including, but not limited to,
          warranties of merchantability, fitness for a particular purpose,
          non-infringement, accuracy, freedom from errors, suitability of
          content, availability, payment or realization of transactions. The
          Company, the WePeso Facility and the WePeso service make no warranty
          in whatsoever manner.
        </p>
        <p>
          {" "}
          The Company does not warrant the content, quality, accuracy, adequacy
          or completeness of the information provided over the WePeso Facility
          and expressly disclaims liability for any errors or omissions in such
          information, in particular over any content and/or other resources
          provided by the third-party in respect of products or services offered
          through or via linkage to the WePeso Facility. The Company does not
          guarantee and promise any specific results from use of products and
          services made available over the WePeso Facility or that the availment
          of any part or all of the WePeso service will be uninterrupted and
          error-free.
        </p>
        <p>
          {" "}
          The Company shall not be responsible for information users or merchant
          partners may post, disclose, publish or share on the WePeso Facility
          or any offensive, inappropriate, obscene, unlawful or otherwise
          objectionable content uploaded, disclosed, published, or shared by
          other users or merchant partners. The Company is not responsible for
          the conduct, whether online or offline, of any user or merchant
          partner of the WePeso Facility or its products or services made
          available through the WePeso Facility.
        </p>
        <p>
          {" "}
          The Company is not responsible for the accuracy of the information,
          content, products or services offered by, or the information practices
          adopted by sites linked to or from the WePeso Facility. Since
          third-party websites, platforms or facilities may have different
          privacy policies and/or security standards governing their sites, we
          advise you to carefully review the privacy policies and terms and
          conditions of these websites, platforms or facilities prior to
          providing any Personal Data. If you provide any Personal Data to any
          third-party websites, platforms or facilities, they may process your
          Personal Data on for its/his/her own purposes, and it/he/she will be
          responsible for its compliance with any applicable law with regard to
          your Personal Data and appropriate disclosures to you. You are advised
          to contact these third parties directly should you have any concerns
          or queries arising therefrom.
        </p>
        <p> Partner Merchant or Third-Party Products and Services</p>
        <p />
        <p>
          {" "}
          For purchases of third-party products and services available over the
          WePeso Facility, diligence is expected of the User to only transact
          with reputable merchants. The Company shall not be held liable and
          accountable for any unauthorized transaction. Once the transaction has
          been authorized, you bind yourself with the merchant’s terms of sale
          such as but not limited to on how you will receive the goods and/or
          services that you purchased (including the validity and expiry of
          voucher code etc).The Company and the Oriente Group is not liable and
          accountable for any undelivered goods and services, defects, damages
          and after-sales services of such goods and /or services of third
          parties. You shall be fully accountable for payment transactions
          especially in dealing with merchants that may have limited refund
          policy for invalid disputes.
        </p>
        <p> Responsibility for WePeso Account</p>
        <p />
        <p>
          {" "}
          You are solely responsible for maintaining the confidentiality of your
          username, password, account and any other login or authentication and
          validation information you created or provided to the WePeso Facility,
          including any mobile one-time password (i.e. one-time-password sent to
          your registered phone number that is valid for only one login session
          or transaction and in a certain period of time, to authenticate and
          identify you.) (“Unique User Credentials”). By creating, registering
          and maintaining your account, you agree to honour all activities
          performed and obligations contracted using your WePeso Account.
        </p>
        <p>
          {" "}
          You agree and confirm that any use of your Unique User Credentials
          shall at all times be presumed to be access by you and, if by a
          third-party, with your consent and authority. Any and all activities
          or transactions using your Unique User Credentials shall be valid and
          binding transactions created, committed and performed by you.
        </p>
        <p>
          {" "}
          If there is an unauthorized use of your account or a breach of its
          security, you hereby undertake to notify the Company of the relevant
          circumstances thereof immediately.
        </p>
        <p> Liability for Account Misuse</p>
        <p />
        <p>
          {" "}
          The Company will not be liable for any loss that you may incur as a
          result of someone else using any of your account or Unique User
          Credentials, either with or without your knowledge. You could be held
          liable for losses incurred by the Company due to a third-party using
          your account or Unique User Credentials.
        </p>
        <p> Account Security</p>
        <p />
        <p>
          {" "}
          While the Company has implemented adequate safeguards as required
          under the Data Privacy Act of 2012, the Company does not give any
          guarantee or warranty with respect to the same. You hereby acknowledge
          that you provide your Personal Data at your own risk.
        </p>
        <p>
          {" "}
          You shall not share your WePeso Account or login information, nor let
          anyone else access your WePeso Account or do anything else that might
          jeopardize the security of your WePeso Account.
        </p>
        <p>
          {" "}
          In the event you become aware of or reasonably suspect any breach of
          security, including without limitation any loss, theft, or
          unauthorized disclosure of the login Information, you must immediately
          notify us and modify your login Information. You are solely
          responsible for maintaining the confidentiality of the login
          Information, and you will be responsible for all uses of the WePeso
          Facility and the login Information, including making of purchases and
          loan applications, whether or not authorized by you. You are
          responsible for anything that happens to your WePeso Account.
        </p>
        <p>
          {" "}
          We reserve the right to remove or reclaim any usernames at any time
          and for any reason, including but not limited to claims by a
          third-party that a user name violates the third-party’s rights
        </p>
        <p> Restrictions on Use</p>
        <p />
        <p>
          {" "}
          You agree to abide by all applicable terms and conditions, laws and
          regulations in your use of the WePeso Facility, and availment of
          products and services available over the WePeso Facility. In addition,
          you agree that you will not do any of the following:
        </p>
        <p>
          {" "}
          register for more than one account at any given time using one
          international mobile equipment identity (IMEI), or register for an
          account on behalf of an individual other than yourself or on behalf of
          any group or entity;
        </p>
        <p>
          {" "}
          post or otherwise make available content, or take any action on the
          Site, that may constitute libel or slander or that infringes or
          violates someone else's rights or is protected by any copyright or
          trademark, or otherwise violates the law;
        </p>
        <p>
          {" "}
          post or or otherwise make available content that in the Company’s
          judgment is objectionable, such as content that is harmful,
          threatening, inflammatory, obscene, fraudulent, invasive of privacy or
          publicity rights, hateful, or otherwise objectionable, or which
          restricts or inhibits any other person from using or enjoying the
          WePeso Facility, or which may expose the Company or users of the
          WePeso Facility to any harm or liability of any type;
        </p>
        <p>
          {" "}
          post or otherwise make available any unsolicited or unauthorized
          advertising, solicitations, promotional materials, or any other form
          of solicitation;
        </p>
        <p>
          {" "}
          use the information or content on the WePeso Facility to send unwanted
          messages to any other user;
        </p>
        <p>
          {" "}
          impersonate any person or entity, or falsely state or otherwise
          misrepresent yourself, your age or your affiliation with any person or
          entity;
        </p>
        <p>
          {" "}
          post or otherwise make publicly available on the WePeso Facility any
          personal or financial information of any third-party;
        </p>
        <p>
          {" "}
          solicit passwords or personally identify information for commercial or
          unlawful purposes;
        </p>
        <p>
          {" "}
          use the WePeso Facility or our products and services in any manner
          that could damage, disable, overburden or impair the WePeso Facility;
        </p>
        <p>
          {" "}
          harvest or collect email addresses or other contact information of
          other users from the WePeso Facility by electronic or other means,
          including the use of automated scripts; and/or
        </p>
        <p>
          {" "}
          post or otherwise make available any material that contains software
          viruses or any other computer code, files or programs designed to
          interrupt, destroy or limit the functionality of any computer software
          or hardware or telecommunications equipment.
        </p>
        <p> Reserved Rights</p>
        <p />
        <p>
          {" "}
          We reserve, at our sole discretion but without obligation, the right
          to:
        </p>
        <p>
          {" "}
          deny access to the WePeso Facility and / or discontinue the WePeso
          service or any component thereof to anyone at any time, temporarily or
          permanently, without giving any reason and/or prior notice to you. You
          hereby irrevocably agree, affirm and warrant to hold the Company and
          the Oriente Group free from any liability, both under equity and the
          law, arising or that may arise out of any such denial of access to the
          WePeso Facility or the discontinuance of the WePeso service;
        </p>
        <p>
          {" "}
          collect, screen, review, flag, filter, modify, block, refuse or remove
          any and/or all information provided by any user, explicitly or
          implicitly to and through the WePeso Facility, including but not
          limited to hardware information, IP address, browser-type related
          information, cookies and the like. You hereby irrevocably agree,
          affirm and warrant to hold the Company and the Oriente Group free from
          any liability, both under equity and the law, arising or that may
          arise out of any such collection, screening, review, flagging,
          filtering, modification, blocking, refusal or removal of any and/or
          all information provided by any user to and through the WePeso
          Facility;
        </p>
        <p>
          {" "}
          enhance, improve, develop and introduce for the WePeso service new
          features and functionalities via the WePeso Facility at any time and
          without prior notice. You hereby understand, agree, and affirm that
          any such enhancement, improvement, development, new feature and/or new
          functionality at the WePeso Facility shall form part of the WePeso
          service as defined herein and thus shall likewise be covered by these
          WePeso Terms of Service and its subsequent revisions or amendments, as
          applicable;
        </p>
        <p>
          {" "}
          verify, check, cross-refer, validate, and ascertain the veracity and
          truthfulness of all information supplied by you by acquiring,
          accessing, retrieving, or otherwise acquiring similar or additional
          information supplied by you to other third-party service providers,
          including, but not limited to telecommunications providers, etc. You
          hereby expressly, unequivocally, and voluntarily allow the Company to
          request for and secure such information, and expressly, unequivocally,
          and voluntarily instruct such third-party providers to: (a) receive
          and process information requests; (b) favourably act at all times on
          any such request by producing the information requested; and (c) when
          requested, provide the latter with certified digital or printed copies
          of the said information;
        </p>
        <p>
          {" "}
          send you or cause to send you service updates and/or messages,
          including SMS, notifications, email and/or any data message
          transmission, informing you of enhancements, improvements,
          developments, features, functionalities, products, promotions, offers,
          advertisement and/or any other information relative to the WePeso
          service. The Company and the WePeso service make no warranty of any
          kind, express or implied, for such service updates and/or messages,
          but you hereby agree to receive such service updates and/or messages
          and hold the Company and the Oriente Group free from any liability
          and/or claims for indemnification or damages that may arise therefrom;
        </p>
        <p>
          {" "}
          set limitations to and charge fees and applicable taxes for the use of
          the WePeso service or the WePeso Facility, at any time and without
          prior notice (other than required regulatory disclosures which will be
          disclosed to you). You further understand and agree that the Company
          and the lender reserves the right, at its sole discretion and under no
          obligation, to change the applicable fees, taxes and charges levied
          for the use of the WePeso service, at any time and with prior written
          notice to you before the effective date of change in fees / charges
          /penalties;
        </p>
        <p>
          {" "}
          to limit the provision, availability, quantity and quality of any
          feature, product or service to any person or to anyone within the same
          geographic area, demographic profile, or any other market, commercial,
          and/or trading segments. You likewise understand and agree that the
          Company reserves the right, at its sole discretion but without
          obligation, to administer and operate any and/or all of the WePeso
          service from any or various locations outside the Republic of the
          Philippines. You hereby understand, agree, and undertake sole
          responsibility for your continued access to the WePeso Facility or use
          of the WePeso service, as well as the results or consequences of such
          access and use, including the responsibility for compliance with
          applicable local laws and the sole liability for non-compliance or
          breach thereof.
        </p>
        <p> Contracts and Authorizations</p>
        <p />
        <p>
          {" "}
          Your use and availment of the products and services on the WePeso
          Facility may be conditioned on your agreement to certain contracts and
          authorizations. By signifying your consent and permissions therein,
          you and agree to be bound terms and conditions thereof and such
          contracts and authorizations shall be deemed validly and voluntarily
          executed by you. Upon request, the Company may furnish you electronic
          copies of such documents.
        </p>
        <p> Termination</p>
        <p />
        <p>
          {" "}
          The Company may terminate or suspend your access to or ability to use
          the WePeso Facility immediately, without prior notice or liability,
          for any reason or no reason, including breach of these WePeso Terms of
          Service.
        </p>
        <p>
          {" "}
          Termination of your access to and use of the WePeso Facility shall not
          relieve you of any obligations arising or accruing prior to
          termination or limit any liability that you otherwise may have to the
          Company or any third-party.
        </p>
        <p> Limitation of Liability</p>
        <p />
        <p>
          {" "}
          Neither Company, nor the lender nor the Oriente Group shall not be
          liable for any loss, cost, compensation, damage or liability to you or
          third-party arising from, directly or indirectly, or as a result of
          any or all of the following:
        </p>
        <p>
          {" "}
          a. refusal of any bank, financial institution, ATM or merchant
          establishment and the like to allow, accept or honour the WePeso
          service;
        </p>
        <p>
          {" "}
          b. the WePeso service is honoured by any bank, financial institution,
          ATM or merchant establishment; however, the transaction is not
          authorized, for any reason whatsoever;
        </p>
        <p>
          {" "}
          c. the User is unable to perform or complete any transaction through
          the use of the WePeso Application or any part of the WePeso Facility
          due to service/ system/ line unavailability;
        </p>
        <p>
          {" "}
          d. any delay, interruption or termination of a transaction whether
          caused by administrative error, technical, mechanical, electrical or
          electronic fault or difficulty or any other reason or circumstance
          beyond the Company and the WePeso service’s control (including but not
          limited to acts of God, strike, labour disputes, fire, disturbance,
          action of government, atmospheric conditions, interference or damage
          by third parties or any change in legislation);
        </p>
        <p>
          {" "}
          e. unauthorized use of any user WePeso Account or any loss, costs,
          damages or payable to any third-party by the WePeso service User; or
        </p>
        <p>
          {" "}
          f. any misrepresentation or fraud by or misconduct of any third-party.
        </p>
        <p>
          {" "}
          To the fullest extent permitted by applicable law, in no event shall
          the Company, its directors, officers, representatives, agents, or
          assigns be liable for any direct, special, indirect or consequential
          damages, or any other damages of any kind, including but not limited
          to loss of use, loss of profits or loss of data, whether in an action
          in contract, tort (including but not limited to negligence) or
          otherwise, arising out of or in any way connected with the use of or
          inability to use the WePeso Facility, including, without limitation,
          any damages caused by or resulting from reliance by user on any
          information obtained from the WePeso Facility, or that result from
          mistakes, omissions, interruptions, deletion of files or email,
          errors, defects, viruses, delays in operation or transmission or any
          failure of performance. You expressly agree that your use of the
          WePeso Facility is at your sole risk.
        </p>
        <p>
          {" "}
          The Company, its directors, officers, representatives, agents, or
          assigns, shall not be liable to you for loss or damage of any kind
          which you may suffer as a result of being a member of the WePeso
          Facility, except where such loss or damage arises from our breach of
          these WePeso Terms of Service or was caused by gross negligence,
          wilful default or fraud by the Company or employees. The Company shall
          also not be responsible for any breach of these WePeso Terms of
          Service arising from circumstances outside our reasonable control.
        </p>
        <p> Liability for Breach</p>
        <p />
        <p>
          {" "}
          You shall be liable for any loss or damage suffered by the Company
          and/or its users as a result of:
        </p>
        <p>
          {" "}
          your breach of these WePeso Terms of Service or any agreement you have
          entered into pursuant to the WePeso Facility’s services;
        </p>
        <p> your fraudulent use of the WePeso Facility; and</p>
        <p> your provision of inaccurate, false or fraudulent data.</p>
        <p> Intellectual Property Rights</p>
        <p />
        <p>
          {" "}
          The design, trademarks, service marks, and logos of the WePeso
          Facility (“WePeso Marks”), are owned by or licensed to the Company,
          similarly the design, trademarks, service marks, and logos of third
          parties may be owned by or licensed to the third parties, and subject
          to copyright and other intellectual property rights under the laws of
          the Republic of the Philippines, foreign laws and/or international
          conventions. You may not use, copy, or distribute of any of the WePeso
          Marks or design, trademarks, service marks, and/or logos of third
          parties found on the WePeso Facility unless otherwise expressly
          permitted.
        </p>
        <p> Changes to WePeso Terms of Service</p>
        <p />
        <p>
          {" "}
          The Company reserves the right to make changes to these WePeso Terms
          of Service (“Updated Terms”) at any time. Unless the changes in the
          Terms of Service are for legal or administrative reasons, the Company
          will provide reasonable advance notice before the Updated Terms become
          effective by posting the Updated Terms on the WePeso Facility.
        </p>
        <p>
          {" "}
          Your use of the WePeso Facility after the effective date of the
          Updated Terms constitutes your agreement to the Updated Terms. You
          should review these Terms of Service and any Updated Terms before
          continuing to use the WePeso Facility.
        </p>
        <p> Miscellaneous</p>
        <p />
        <p>
          {" "}
          In the event that any provision of these WePeso Terms of Service is
          deemed by any competent authority to be unenforceable or invalid, the
          relevant provision shall be modified to allow it to be enforced in
          line with the intention of the original text to the fullest extent
          permitted by applicable law. The validity and enforceability of the
          remaining provisions of these WePeso Terms of Service shall not be
          affected.
        </p>
        <p>
          {" "}
          You agree that all documents or notices may be delivered to you
          electronically, through your e-mail address provided upon
          registration. Accordingly, you recognize and acknowledge that it is
          your sole responsibility to update the Company with your current
          e-mail address and the Company shall not be liable for any claim of
          loss or damage for failure to receive notices.
        </p>
        <p>
          {" "}
          Subject to applicable law, all disclaimers, indemnities and exclusions
          in these WePeso Terms of Service shall survive termination of any loan
          agreement(s).
        </p>
        <p>
          {" "}
          No single or partial exercise, or failure or delay in exercising any
          right, power or remedy by us shall constitute a waiver by us of, or
          impair or preclude any further exercise of, that or any right, power
          or remedy arising under these WePeso Terms of Service or otherwise.
        </p>
        <p>
          {" "}
          Unless expressly agreed in writing otherwise, these WePeso Terms of
          Service set out the entire agreement between you and us with respect
          to your use of the WePeso Facility and supersedes any and all
          representations, communications and prior agreements (written or oral)
          made by you or us.
        </p>
        <p>
          {" "}
          These WePeso Terms of Service are governed by and construed in
          accordance with laws of the Republic of the Philippines. All matters,
          claims or disputes arising out of or in connection with these WePeso
          Terms of Service, shall be submit to the exclusive jurisdiction of the
          courts of Pasig City.
        </p>
        <p>
          {" "}
          This version of the Terms of Service is last updated on 22 August 2019
        </p>
        <p> Lender Authorization</p>
        <p />
        <p>
          {" "}
          By accepting these Terms of Service, you understand that as a lender
          you assume the risk of non-payment. To mitigate this risk, the Company
          may, to the extent possible, assign any Non-Performing Loan* (“NPL”)
          to a third-party, in each case endeavouring that you shall receive the
          unpaid principal, interest, and penalties which you are entitled to
          receive under the terms and conditions of the loan. In the event that
          the loan or financial product is assigned, ceded, taken over,
          purchased or is otherwise transferred to any other party, all loan and
          financial product records, transactions, and any other information
          (including personal and sensitive personal information), as it relates
          to the issuance, history, basis, and status of such loan or financial
          instrument, shall be shared, transferred and delivered to such
          third-party.
        </p>
        <p>
          {" "}
          In accordance with the above, you hereby authorize and designate the
          Company, as your authorized representative and attorney-in-fact to
          assign, sell, or otherwise dispose/assign the NPL to a third-party,
          upon prior notice. The Company will inform you, via email of such
          assignment and shall credit the unpaid principal, interest, and
          penalties to your account on record.
        </p>
        <p> USER ACCEPTANCE</p>
        <p />
        <p>
          {" "}
          I confirm having read and understood and agree to the foregoing WePeso
          Terms of Service.
        </p>
        <p>
          {" "}
          By accepting these WePeso Terms of Service and the WePeso Privacy
          Policy I signify your express consent in accordance with Republic Act
          No. 10173, otherwise referred to as the Data Privacy Act of 2012 and
          its Implementing Rules and Regulations as well as other applicable
          confidentiality and data privacy laws of the Republic of the
          Philippines. I agree to hold the Company, its officers, directors and
          stockholders, free and harmless from any and all liabilities, damages,
          actions, claims, and suits in connection with the implementation or
          processing of Personal Data in relation to my consent or authorization
          and my use of the WePeso Facility under these Terms of Service.
        </p>
        <p> DIGITALLY SIGNED</p>
        <p />
        <p>
          {" "}
          I HAVE READ THESE TERMS OF SERVICE AND AGREE TO ALL OF THE PROVISIONS
          CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE
          “SIGN UP” OR “CONNECT” OR “REGISTER” OR “SUBMIT” BUTTON OF ANY SOCIAL
          MEDIA OR PUBLIC PLATFORMS LINKED TO THE WePeso SERVICE, I UNDERSTAND
          THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND TO HAVE THE
          SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.
        </p>
        <p>
          {" "}
          *A Non-Performing Loan is defined as any loan that is in default, as
          provided under the applicable loan document.
        </p>
      </div>
    ) : localCountryKey === "cl" ? (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Terminos de Uso" />
        <h2> Terminos de Uso</h2>
        <p />

        <p>1. INTRODUCCIÓN</p>
        <p></p>
        <p>
          El presente documento establece los términos y condiciones de uso
          (“Términos y Condiciones”) que serán aplicables a aquellos que accedan
          al Sitio web (“Sitio Web”) y/o Aplicación (“Aplicación/App”).
        </p>
        <p></p>
        <p>
          Es necesario también leer la Política de Privacidad y Protección de
          Datos Personales (“Política de Privacidad”), en donde detallamos
          nuestras prácticas de tratamiento de información
          https://www.we-peso.com/acuerdoDePrivacidad . Nos preocupamos por
          mantener seguros a nuestros Usuarios y estamos en constante trabajo
          para recopilar la información de manera confidencial.
        </p>
        <p></p>
        <p>
          La utilización del Sitio Web y/o Aplicación/App implica la aceptación
          sin reservas de todas las disposiciones contenidas en los presentes
          Términos y Condiciones, en términos de la segunda fracción del
          Artículo No. 141 y 141-A del Código Civil, los cuales son tanto
          vinculantes como obligatorios. En caso alguno de los mencionados
          anteriormente no esté conforme con el presente documento, deberá
          abstenerse de acceder y utilizar el Sitio Web y/o Aplicación/App para
          hacer uso del servicio ofrecido por WePeso.
        </p>
        <p></p>
        <p>
          En caso de incumplimiento a cualquiera de las disposiciones contenidas
          en los presentes Términos y Condiciones, WePeso podrá suspenderles o
          negarles el acceso al Sistema, en cualquier momento y sin necesidad de
          notificación previa de ningún tipo, así como presentar las denuncias,
          querellas o reclamaciones que sean procedentes ante las autoridades
          competentes, proporcionando la información necesaria para la
          investigación y persecución del ilícito o delito del que se trate.
        </p>
        <p></p>
        <p>
          Se considerará infracción a los presentes Términos y Condiciones,
          entre otros, la comisión o participación en actos que constituyan
          conductas abusivas, inapropiadas o ilícitas, actividades fraudulentas
          o ilegales.
        </p>
        <p></p>
        <p>2. DEFINICIONES</p>
        <p></p>
        <p>
          Sin perjuicio de los términos definidos en diversas secciones del
          presente documento, los términos aquí utilizados con la primera letra
          mayúscula y que no sean definidos de forma diversa, tendrán el
          significado que a continuación se les atribuye, pudiendo ser
          utilizados en masculino, femenino o neutro, así como en singular o en
          plural, indistintamente y según el contexto:
        </p>
        <p></p>
        <p>
          ● “Afiliados” significa, en relación con una parte, cualquier entidad
          que controla, está bajo el control o está bajo control común con esa
          parte, donde el control significa la propiedad directa o indirecta de
          más del 50 por ciento del capital con derecho a voto o derecho similar
          de propiedad de esa parte o el poder legal para dirigir o causar la
          dirección de la administración general y las políticas de esa parte,
          ya sea a través de la propiedad del capital con derecho a voto, por
          contrato o de otra manera, y los controles y controlados se
          interpretarán en consecuencia;
        </p>
        <p></p>
        <p>
          ● “Servicio” significa cualquier forma de servicio o producto
          financiero que podamos ofrecerle de conformidad con estos Términos y
          Condiciones y, de tiempo en tiempo, puede suscribirse y el “Servicio”
          se interpretará en consecuencia;
        </p>
        <p></p>
        <p>
          ● “Cuenta” significa su cuenta individual mientras accede al Sistema y
          utiliza el Servicio;
        </p>
        <p></p>
        <p>
          ● “Aplicación/App” significa la aplicación electrónica suministrada
          por WePeso y/o sus Afiliadas para que el Usuario acceda al Sistema y
          al Servicio;
        </p>
        <p></p>
        <p>
          ● “Sitio web” significa cualquier sitio web operado por WePeso o
          cualquiera de sus Afiliadas de tiempo en tiempo;
        </p>
        <p></p>
        <p>
          ● “El Usuario/Usuario” significa a aquella persona natural o jurídica
          que utiliza los Servicios de WePeso por medio del Sitio Web y/o
          Aplicación/App;
        </p>
        <p></p>
        <p>
          ● “Leyes Vigentes”: significan todas las leyes, códigos, normas
          jurídicas, ordenanzas, reglamentos, tratados, reglas, normas,
          permisos, resoluciones, órdenes, interpretaciones, decisiones,
          interdictos, así como las normas, resoluciones y sentencias que
          actualmente o en el futuro estén vigentes en Chile y resulten
          aplicables;
        </p>
        <p></p>
        <p>
          ● “Credenciales” significa Sus credenciales personales utilizadas para
          acceder al Sitio Web y/o Aplicación/App y operar su Cuenta;
        </p>
        <p></p>
        <p>
          ● “Central de Riesgo” significa aquella empresa cuya finalidad es
          proporcionar información sobre el historial crediticio de terceros;
        </p>
        <p></p>
        <p>
          ● “Historial Crediticio” significa un informe que muestra tu
          antecedente como receptor de préstamos, elaborado por una Central de
          Riesgo;
        </p>
        <p></p>
        <p>
          ● “Información confidencial” significa cualquier información y datos
          confidenciales o de propiedad de WePeso o nuestros Afiliados,
          divulgados a El Usuario en relación con estos Términos y Condiciones,
          ya sea divulgados electrónicamente, oralmente o por escrito o mediante
          otros métodos disponibles para El Usuario.
        </p>
        <p></p>
        <p>
          ● “Préstamo” significa el monto principal del préstamo otorgado o que
          WePeso le hará a El Usuario conforme a estos Términos y Condiciones;
        </p>
        <p></p>
        <p>
          ● “Dispositivo Móvil” incluye y significa su teléfono móvil, tarjeta
          SIM y / u otro equipo que, cuando se usan juntos, le permite acceder a
          la red y usar la Aplicación;
        </p>
        <p></p>
        <p>
          ● “Operador de Red Móvil” significa un operador de red móvil en Chile
          registrado por el Organismo Supervisor de Inversión Privada en
          Telecomunicaciones;
        </p>
        <p></p>
        <p>
          ● “Cuenta Bancaria” se refiere a aquel producto financiero, donde su
          principal función es ser lugar donde depositar el dinero físico para
          su movimiento y administración;
        </p>
        <p></p>
        <p>
          ● “Entidad Bancaria” se refiere a aquellas entidades que tienen en
          custodia el dinero que las personas y empresas depositan, otorgando
          préstamos, y usando esos recursos y para realizar inversiones, o entre
          otras actividades que la ley les autoriza;
        </p>
        <p></p>
        <p>
          ● “Tarjeta Bancaria” se refiere a aquel medio de pago emitido por una
          entidad financiera a favor del Usuario, a través de una cuenta
          asociada, y que es aceptada en infinidad de comercios y
          establecimientos;
        </p>
        <p></p>
        <p>
          ● “Información Personal” significa datos, ya sean verdaderos o no,
          sobre un individuo que puede identificarse a partir de esos datos, o
          de esos datos y otra información a la que tenemos o es probable que
          tengamos acceso. Esto puede incluir el nombre de un individuo,
          identificación nacional, fecha de nacimiento, sexo, antecedentes
          educativos, religión, fotografía, dirección física, dirección de
          correo electrónico, información de trabajo, estado civil, contacto de
          emergencia, número de teléfono, detalles de la tarjeta SIM,
          información financiera y de crédito. información (incluidos los
          detalles de la Cuenta bancaria), el ID de Cuenta y/o la contraseña que
          se utilizarán para acceder a la Aplicación después del registro y otra
          información que, cuando se toma junto con otra información, permitir
          que un individuo sea identificado;
        </p>
        <p></p>
        <p>
          ● “Solicitud” significa una solicitud o instrucción recibida por
          WePeso del Usuario o supuestamente del Usuario a través del Sistema y
          sobre la cual estamos autorizados a actuar;
        </p>
        <p></p>
        <p>
          ● “Tarjeta SIM” significa el módulo de identidad del suscriptor que,
          cuando se usa con el teléfono móvil apropiado, le permite acceder a la
          red y usar su Cuenta;
        </p>
        <p></p>
        <p>
          ● “SMS” significa un servicio de mensajes cortos que consiste en un
          mensaje de texto transmitido desde su Dispositivo Móvil a otro;
        </p>
        <p></p>
        <p>
          ● “Sistema” significa el sistema WePeso provisto por WePeso para
          proporcionar a los Usuarios el Servicio, incluido el Sitio Web y/o
          Aplicación/App y otros softwares y servicios de soporte relacionados;
        </p>
        <p></p>
        <p>
          ● “Territorio” significa el territorio que abarca la República del
          Chile;
        </p>
        <p></p>
        <p>
          ● “Las tarifas de transacción” incluyen las tarifas y cargos pagaderos
          por el uso del Servicio según lo publicado por WePeso en la Aplicación
          o por cualquier otro medio que determinemos a Nuestro exclusivo
          criterio. Las tarifas de transacción están sujetas a cambios en
          cualquier momento a nuestro exclusivo criterio;
        </p>
        <p></p>
        <p>
          ● “Cookies”: son los archivos de datos que se almacenan en el
          dispositivo del Usuario, cuando éstos tienen acceso al Sitio Web o la
          Aplicación/App.
        </p>
        <p></p>
        <p>3. RESPONSABILIDADES DEL USUARIO</p>
        <p></p>
        <p>El Usuario confirma y se compromete con WePeso que:</p>
        <p></p>
        <p>
          3.1 Tiene pleno poder y autoridad para celebrar y estar legalmente
          obligado por estos Términos y Condiciones y cumplir con sus
          obligaciones en virtud de estos Términos y Condiciones; 3.2 Deberá
          cumplir en todo momento con todas las Leyes Vigentes y estos Términos
          y Condiciones, y nos notificará si incumple alguna de las Leyes
          Vigentes o estos Términos y Condiciones; 3.3 Solo usará el Sistema y
          el Servicio para fines legales y solo para los fines para los que está
          destinado a ser utilizado; 3.4 Deberá asegurarse de que todos los
          documentos, Información Personal y Credenciales que nos haya
          proporcionado (o en su nombre) a WePeso o de otro modo a través del
          sistema sean precisos, actuales, completos y no engañosos;3.5 Solo
          utilizará una Cuenta que esté autorizado a utilizar; 3.6 No deberá
          participar en ninguna conducta fraudulenta, engañosa o engañosa; y 3.7
          No perjudicará ni eludirá el funcionamiento adecuado de la red en la
          que opera el Sistema.
        </p>
        <p></p>
        <p>4. ACEPTACIÓN DE ESTOS Términos y Condiciones</p>
        <p></p>
        <p>
          4.1. El Usuario declara haber leído detenidamente -y comprendido-
          todos los términos establecidos en estos Términos y Condiciones y
          enmendados periódicamente por WePeso antes de descargar la
          Aplicación/App, o registrar una Cuenta con WePeso, que regirá el uso y
          el funcionamiento de la Aplicación/App y Cuenta. 4.2. Después de
          descargar la Aplicación, se considerará que acepta estos Términos y
          Condiciones al hacer clic en la opción “Aceptar” en nuestro sistema y
          le pedirá que confirme que ha leído, entendido y aceptado cumplir con
          estos Términos y Condiciones. 4.3. Al descargar la Aplicación y
          registrar una Cuenta, El Usuario acepta cumplir y estar sujeto a estos
          Términos y Condiciones que rigen el funcionamiento de la Cuenta y
          afirma que los Términos y Condiciones del presente documento son sin
          perjuicio de cualquier otro derecho que podamos tener con respecto a
          la cuenta legal o de otro tipo. 4.4. Estos Términos y Condiciones
          pueden ser modificados por WePeso de tiempo en tiempo y el uso
          continuado del Servicio constituye su aceptación de estar sujeto a los
          Términos y Condiciones de cualquier modificación o variación.
          Tomaremos todas las medidas razonables para notificarle cualquier
          cambio. 4.5. De tiempo en tiempo, se pueden emitir actualizaciones de
          la Aplicación y/o del Sitio web. Dependiendo de la actualización, es
          posible que no pueda usar el Servicio hasta que haya descargado o
          transmitido la última versión de la Aplicación y haya aceptado los
          nuevos términos y condiciones de estos Términos y Condiciones. 4.6. Al
          usar la Aplicación o cualquiera de los Servicios, El Usuario acepta
          que recopilemos y usemos información técnica sobre el Dispositivo
          Móvil y el software, hardware y periféricos relacionados para el
          Servicio que están basados en internet o inalámbricos para mejorar
          nuestros productos y proporcionar cualquier Servicio. Si utiliza estos
          Servicios, autoriza a WePeso y a nuestros Afiliados y licenciatarios a
          transmitir, recopilar, retener, mantener, procesar y usar Sus datos
          para determinar nuestros servicios de calificación crediticia o para
          mejorar nuestro Servicio y/o Su experiencia al usar el App. 4.7. El
          Usuario autoriza expresamente a WePeso, para que por conducto de
          nuestros funcionarios facultados llevemos a cabo investigaciones sobre
          su comportamiento crediticio en la Central de Riesgo que estimemos
          conveniente. Así mismo, declara que conoce la naturaleza y el alcance
          de la Información Personal que se solicitará, del uso que haremos de
          tal Información Personal y de que podremos realizar consultas
          periódicas de su Historial Crediticio, consintiendo que esta
          autorización se encuentre vigente por un período de 3 años contados a
          partir de la fecha de su expedición y en todo caso durante el tiempo
          que mantengamos relación jurídica. 4.8. También nos autoriza
          expresamente a contactar a El Usuario y a su contacto de emergencia
          que lo haya acordado expresamente, para verificar su Información
          Personal o cuando no podamos comunicarnos con El Usuario a través de
          otros métodos o cuando no hayamos recibido su pago en relación con el
          Préstamo establecido en Cláusula 11 de este documento. 4.9. El Usuario
          autoriza a WePeso a usar esta Tarjeta Bancaria para la deducción
          automática.
        </p>
        <p></p>
        <p>5. SU USO DEL SERVICIO</p>
        <p></p>
        <p>
          5.1. El Servicio ofrecido por WePeso sólo puede ser utilizado por
          personas mayores de 18 años. 5.2. Por la presente, reconoce y acepta
          que la aceptación por parte de WePeso sobre Solicitud de una Cuenta no
          crea ninguna relación contractual entre El Usuario y las Entidades
          Bancarias más allá de los términos y condiciones que se aplican a su
          Cuenta Bancaria. 5.3. Nos reservamos el derecho de rechazar su
          Solicitud de Préstamo o de revocarla en cualquier momento a nuestra
          exclusiva y absoluta discreción y sin asignar ningún motivo ni
          notificarlo. 5.4. Nos reservamos el derecho (a nuestro exclusivo y
          absoluto criterio) de emitir, y/o rechazar un Préstamo y/o variar los
          términos de cualquier Préstamo dependiendo de nuestra evaluación del
          perfil de crédito del Usuario de tiempo en tiempo. Los términos del
          Préstamo y la tasa de interés pagadera en relación con cada Solicitud
          de Préstamo se mostrarán en la Aplicación.
        </p>
        <p></p>
        <p>6. SU USO DEL SISTEMA</p>
        <p></p>
        <p>Derechos otorgados y reservados</p>
        <p></p>
        <p>
          6.1. Sujeto a su cumplimiento de estos Términos y Condiciones, WePeso
          y nuestros licenciantes (si corresponde) le otorgamos una licencia de
          acceso al Sistema revocable, limitada, no exclusiva, no transferible y
          libre de regalías durante el plazo de estos Términos y Condiciones y
          en el Territorio , para acceder y utilizar el Sistema únicamente para
          su uso personal con el fin de adquirir el Servicio proporcionado por
          WePeso. 6.2. Todos los derechos que no se le otorgan expresamente en
          virtud de estos Términos y Condiciones están reservados por WePeso y
          nuestros otorgantes de licencias (si corresponde). Nada en estos
          Términos y Condiciones transfiere a El Usuario ninguna propiedad del
          Sistema (en su totalidad o en parte).
        </p>
        <p></p>
        <p>Conductas prohibidas</p>
        <p></p>
        <p>6.3. Mientras usa el Sistema, no deberá:</p>
        <p></p>
        <p>
          6.3.1. licenciar, sublicenciar, vender, revender, transferir, asignar,
          distribuir o explotar comercialmente o poner a disposición de terceros
          de cualquier otra forma el Sistema; 6.3.2. modificar o realizar
          trabajos derivados basados en el Sistema, o realizar ingeniería
          inversa o acceder al software subyacente por cualquier motivo; 6.3.3.
          usar el Sistema para construir un producto o Servicio competitivo,
          construir un producto usando ideas, características, funciones o
          gráficos similares al Sistema, copiar ideas, características,
          funciones o gráficos del Sistema, o lanzar un programa o script
          automatizado que puede realizar múltiples solicitudes de servidor por
          segundo, o que carga o dificulta indebidamente la operación y / o el
          rendimiento del Sistema, o intenta obtener acceso no autorizado al
          Sistema o sistemas o redes relacionados; 6.3.4. usar cualquier
          aplicación o proceso para recuperar, indexar, “extraer datos”, o de
          cualquier manera reproducir o eludir la estructura de navegación,
          presentación o contenido del Sistema; 6.3.5. publicar, distribuir o
          reproducir de cualquier manera cualquier material con derechos de
          autor, marcas comerciales u otra Información Condifencial de propiedad
          sin obtener el consentimiento previo del propietario de dichos
          derechos de propiedad, o eliminar cualquier aviso de derechos de
          autor, marca registrada u otros derechos de propiedad contenidos en el
          Sistema; 6.3.6. enviar o almacenar cualquier material con fines
          ilegales o fraudulentos; 6.3.7. enviar spam u otros mensajes no
          solicitados, o causar molestias, molestias, inconvenientes o hacer
          reservas falsas; 6.3.8. enviar o almacenar material infractor,
          obsceno, amenazante, difamatorio o ilegal o tortuoso; 6.3.9. enviar
          material que contenga virus de software, gusanos, troyanos u otro
          código informático, archivos, scripts, agentes o programas dañinos;
          6.3.10. interferir o alterar la integridad o el rendimiento del
          Sistema o los datos contenidos en el mismo; 6.3.11. hacerse pasar por
          cualquier persona o entidad o tergiversar su afiliación con una
          persona o entidad; 6.3.12. tergiversar deliberadamente su ubicación; o
          6.3.13. tergiversar cualquier Información Personal importante sobre El
          Usuario, o que pueda relacionar nuestra decisión de participar en
          cualquier negocio relacionado u otro con El Usuario; 6.3.14. dañar
          nuestra reputación o la de cualquiera de las compañías de nuestro
          grupo de alguna manera; 6.3.15. recopilar o recolectar cualquier
          Información Confidencial o datos de cualquier servicio o nuestros
          sistemas o intentar descifrar cualquier transmisión hacia o desde los
          servidores que ejecutan cualquier Servicio.
        </p>
        <p></p>
        <p>7. SU CUENTA</p>
        <p></p>
        <p>
          7.1. Para acceder al Sistema como Usuario, debe registrarse y mantener
          una Cuenta como Usuario de la Aplicación.
        </p>
        <p></p>
        <p>
          7.2. El Usuario es responsable de todas las actividades realizadas en
          su Cuenta, El Usuario:
        </p>
        <p></p>
        <p>
          7.2.1. solo debe tener una Cuenta; 7.2.2. debe mantener la Información
          Personal de su Cuenta confidencial y segura; 7.2.3. no debe
          proporcionar a ninguna otra persona acceso a su Cuenta, incluso para
          transferir la Cuenta o la Información Personal de su Cuenta a otra
          persona; 7.2.4. debe notificarnos de inmediato si sospecha que tienen
          acceso o uso no autorizado a su Cuenta; y
        </p>
        <p></p>
        <p>
          7.3. Nos reservamos el derecho de bloquear o denegar el acceso a su
          Cuenta, y/o bloquear las funciones disponibles en la Aplicación, sin
          perjuicio de nuestros otros derechos y recursos:
        </p>
        <p></p>
        <p>
          7.3.1. si consideramos, a nuestro exclusivo criterio, que El Usuario
          ha violado cualquier término de estos Términos y Condiciones; 7.3.2.
          durante una investigación; 7.3.3. si nos debe capital, intereses,
          tasas de transacción o impuestos a WePeso o cualquiera de las
          Compañías de nuestro Grupo; 7.3.4. si estos Términos y Condiciones se
          rescinden por cualquier motivo; o 7.3.5. en cualquier otro momento a
          nuestra discreción razonable.
        </p>
        <p></p>
        <p>8. SU INFORMACIÓN PERSONAL</p>
        <p></p>
        <p>
          El Usuario acepta y consiente que usemos y procesemos su Información
          Personal tal como se establece en la Política de Privacidad
          https://www.we-peso.com/acuerdoDePrivacidad , modificada
          periódicamente por WePeso.
        </p>
        <p></p>
        <p>9. SOLICITUDES HECHAS POR El Usuario</p>
        <p></p>
        <p>
          9.1. Por la presente, nos autoriza irrevocablemente a actuar sobre
          todas las Solicitudes que recibimos del Usuario (o supuestamente del
          Usuario) a través del Sistema y a responsabilizarlo con respecto a las
          mismas.
        </p>
        <p></p>
        <p>
          9.2. Sujeto a nuestra discreción, nos reservamos el derecho de
          rechazar cualquier Solicitud en relación con una Solicitud de Préstamo
          del Usuario, incluso si previamente le hemos emitido un Préstamo.
        </p>
        <p></p>
        <p>
          9.3. Tendremos derecho a aceptar y actuar sobre cualquier Solicitud,
          incluso si esa Solicitud es de otro modo incompleta o ambigua si, a
          nuestra absoluta discreción, creemos que puede corregir la Información
          Personal incompleta o ambigua en la Solicitud sin ninguna referencia a
          ti siendo necesario.
        </p>
        <p></p>
        <p>
          9.4. Se considerará que hemos actuado correctamente y que hemos
          cumplido todas las obligaciones que se le adeudan a pesar de que la
          Solicitud puede haberse iniciado, enviado o comunicado de otra manera
          por error o de manera fraudulenta, y El Usuario estará obligado por
          cualquier Solicitud en la que podamos actuar si hemos actuado de buena
          fe creyendo que El Usuario ha enviado tales instrucciones.
        </p>
        <p></p>
        <p>
          9.5. Podemos, a nuestra absoluta discreción, negarnos a actuar de
          acuerdo con o en su totalidad o en parte de su Solicitud a la espera
          de una nueva consulta o confirmación adicional (ya sea por escrito o
          no) del Usuario.
        </p>
        <p></p>
        <p>
          9.6. El Usuario acepta y nos liberará e indemnizará de todos los
          reclamos, pérdidas, daños, costos y gastos que surjan como
          consecuencia de, o de alguna manera relacionados con WePeso, haber
          actuado de acuerdo con la totalidad o parte de cualquiera de sus
          Solicitudes (o no ejerció) la discreción que le fue conferida.
        </p>
        <p></p>
        <p>
          9.7. El Usuario reconoce que, en la medida en que lo permita la Ley
          Aplicable, no seremos responsables de ningún sorteo, transferencia,
          envío, divulgación, actividad o incidente no autorizados en su Cuenta
          por el conocimiento y / o uso o manipulación de Su PIN de la Cuenta,
          contraseña, identificación o cualquier medio, ya sea ocasionado por su
          negligencia.
        </p>
        <p></p>
        <p>
          9.8. Estamos autorizados a ejecutar las órdenes con respecto a su
          Cuenta, según lo requiera cualquier orden judicial o autoridad o
          agencia competente según la Ley Aplicable.
        </p>
        <p></p>
        <p>
          9.9. En caso de conflicto entre los términos de cualquier Solicitud
          recibida por WePeso del Usuario y estos Términos y Condiciones,
          prevalecerán estos Términos y Condiciones.
        </p>
        <p></p>
        <p>10. MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN</p>
        <p></p>
        <p>
          El Usuario acepta y consiente que para los casos en los que sea
          necesario su consentimiento expreso, podrá hacerlo con su aceptación
          mediante un “aceptar” (accept por su significado en inglés) en la
          “casilla o recuadro de selección” (checkbox por su significado en
          inglés), a través de medios electrónicos, incluyendo la Aplicación, o
          por cualquier otro medio contemplado en legislación aplicable a las
          operaciones de este Contrato.
        </p>
        <p></p>
        <p>11. SUS RESPONSABILIDADES</p>
        <p></p>
        <p>
          11.1. El Usuario deberá, a su propio cargo, proporcionar y mantener en
          un orden de operación seguro y eficiente su Dispositivo Móvil
          necesario para acceder al sistema y al servicio.
        </p>
        <p></p>
        <p>
          11.2. El Usuario será responsable de garantizar el rendimiento
          adecuado de su Dispositivo Móvil. No seremos responsables de los
          errores o fallas causados por un mal funcionamiento de su Dispositivo
          Móvil, ni seremos responsables de ningún virus informático o problemas
          relacionados que puedan estar asociados con el uso del Sistema, el
          Servicio y el Dispositivo Móvil. El Usuario será responsable de los
          cargos debidos a cualquier Proveedor de Servicios que le proporcione
          conexión a la red y no seremos responsables de las pérdidas o demoras
          causadas por dicho Proveedor de Servicios.
        </p>
        <p></p>
        <p>
          11.3. Su acceso a la Aplicación será a través de su Dispositivo Móvil.
          Es su responsabilidad verificar y asegurarse de descargar la
          Aplicación correcta para su Dispositivo Móvil. No somos responsables
          si no tiene un dispositivo compatible o si no tiene la última versión
          de la Aplicación para su Dispositivo Móvil.
        </p>
        <p></p>
        <p>
          11.4. Si su Dispositivo Móvil se pierde, se lo roban, se rompe y/o ya
          no está en su poder, y esto expone la Información Personal de su
          Cuenta y sus Credenciales a otra persona o afecta nuestros derechos
          y/o recursos legales, debe notificarnos de inmediato y seguir los
          procedimientos notificados por WePeso. No seremos responsables de la
          divulgación de la Información Personal de su Cuenta y sus Credenciales
          a ningún tercero y por la presente acepta indemnizarlos y eximirnos de
          cualquier pérdida resultante de la divulgación de la Información
          Personal de su Cuenta y sus Credenciales.
        </p>
        <p></p>
        <p>
          11.5. El Usuario es el único responsable de contar con un plan móvil y
          de Internet apropiado y de cualquier tarifa cobrada por su Operador de
          Red Móvil, como las tarifas de teléfono, SMS y datos de Internet. El
          Usuario reconoce que Suuso del Sistema puede consumir grandes
          cantidades de datos y que El Usuario será el único responsable de
          dicho uso y las tarifas asociadas.
        </p>
        <p></p>
        <p>
          11.6. Deberá seguir todas las instrucciones, procedimientos y términos
          contenidos en estos Términos y Condiciones y cualquier documento
          provisto por WePeso sobre el uso del Sistema y el Servicio.
        </p>
        <p></p>
        <p>
          11.7. Deberá tomar todas las precauciones razonables para detectar
          cualquier uso no autorizado del Sistema y el Servicio. Con ese fin, se
          asegurará de que todas las comunicaciones de WePeso sean examinadas y
          verificadas por El Usuario o en su nombre tan pronto como sea posible
          después de recibirlas de tal manera que se detecte cualquier uso no
          autorizado y acceso al Sistema. Deberá informarnos de inmediato en
          caso de que:
        </p>
        <p></p>
        <p>
          11.7.1. Tiene motivos para creer que sus Credenciales son o pueden ser
          conocidas por cualquier persona no autorizada a saber lo mismo y / o
          que se haya visto comprometida; y/o 11.7.2. El Usuario tiene razones
          para creer que el uso no autorizado del Servicio ha ocurrido o puede
          haber ocurrido o podría ocurrir, y una transacción puede haber sido
          ingresada o comprometida de manera fraudulenta. 11.7.3. Deberá seguir
          en todo momento los procedimientos de seguridad que le notifiquemos de
          tiempo en tiempo o cualquier otro procedimiento que pueda ser
          aplicable al Servicio de tiempo en tiempo. El Usuario reconoce que
          cualquier incumplimiento por su parte de seguir los procedimientos de
          seguridad recomendados puede resultar en una violación de la
          confidencialidad de su Cuenta. En particular, se asegurará de que el
          Servicio no se utilice o de que no se emitan Solicitudes o de que las
          funciones relevantes no sean realizadas por nadie que no sea una
          persona autorizada por El Usuario para hacerlo.
        </p>
        <p></p>
        <p>12. TÉRMINOS FINANCIEROS</p>
        <p></p>
        <p>
          12.1. WePeso mostrará los intereses pagaderos por El Usuario en
          relación con cualquier Préstamo en la Aplicación. Mientras tanto,
          tendremos derecho a establecer y cobrar tarifas de transacción, en
          relación con su uso del Servicio y de tiempo en tiempo modificar o
          variar nuestras tarifas de transacción por el Servicio. Si decidimos
          comenzar a cobrar tarifas de transacción o, si corresponde, variar o
          modificar nuestras tarifas de transacción, Las tarifas de transacción
          pagaderas en cualquier nueva solicitud de Servicio se mostrarán en la
          Aplicación. Haremos todos los esfuerzos razonables para notificarle
          cualquier cambio en relación con Las tarifas de transacción dentro de
          un período razonable antes de que dichos cambios se implementen,
          incluida la visualización de avisos de los cambios en la Aplicación.
        </p>
        <p></p>
        <p>
          12.2. Si no realiza los pagos que se nos adeudan en la fecha de
          vencimiento del pago, estaremos autorizados a aplicar cargos por
          demora sobre el monto prestado a El Usuario a una tasa que se mostrará
          por adelantado en la Aplicación
        </p>
        <p></p>
        <p>Impuestos</p>
        <p></p>
        <p>
          12.3. Todos los pagos que debe realizar en relación con estos Términos
          y Condiciones y Préstamo se calculan sin tener en cuenta los impuestos
          que deba pagar. Si se paga algún impuesto en relación con el pago,
          debe pagarnos un monto adicional igual al pago multiplicado por la
          tasa impositiva apropiada. Debe hacerlo al mismo tiempo que realiza el
          pago o cuando realicemos dicha demanda, incluso después del final o la
          finalización de la relación.
        </p>
        <p></p>
        <p>
          12.4. Por el presente, El Usuario acepta y acepta que podemos retener
          cantidades en su Cuenta si alguna autoridad fiscal nos exige que lo
          hagamos, o si la ley o los acuerdos con cualquier autoridad fiscal nos
          exigen que lo hagamos, o si tenemos que cumplir con políticas internas
          o con cualquier orden aplicable o sanción de una autoridad fiscal.
        </p>
        <p></p>
        <p>Pagos</p>
        <p></p>
        <p>
          12.5. El principal, los intereses, los honorarios de transacción y los
          impuestos en relación con estos Términos y Condiciones y el Préstamo
          pagadero por El Usuario a WePeso deben pagarse mediante los métodos de
          pago antes / en la fecha de vencimiento ofrecida y mostrada en la
          Aplicación de tiempo en tiempo.
        </p>
        <p></p>
        <p>
          12.6. Todos los pagos deben realizarse en la moneda local del
          Territorio, y a través de las Entidades Bancarias.
        </p>
        <p></p>
        <p>
          12.7. Acepta y está consciente de que todos los reembolsos/pagos deben
          realizarse a la Cuenta designada de acuerdo con el proceso de pago
          instruido o requerido por la Aplicación WePeso, de lo contrario, la
          Aplicación WePeso no puede recibir el reembolso dEl Usuario y tiene
          derecho a solicitar al Usuario a pagar el saldo restante. Si El
          Usuario paga a una Cuenta no indicada por la Aplicación, El Usuario
          asumirá todas las responsabilidades por sí mismo y no tendrá nada que
          ver con la Aplicación.
        </p>
        <p></p>
        <p>13. PREDETERMINADO</p>
        <p></p>
        <p>13.1. Un evento de incumplimiento ocurre cuando El Usuario:</p>
        <p></p>
        <p>
          13.1.1. no paga ninguna suma o cuota (incluidos todos los intereses
          devengados, los honorarios de transacción y el impuesto ）pagadero por
          un Préstamo otorgado en virtud de estos Términos y Condiciones por un
          período de quince (15) días acumulativos a menos que la falta de pago
          sea causada únicamente por un error administrativo o problema técnico;
          o se declaran en quiebra.
        </p>
        <p></p>
        <p>
          13.2. En cualquier momento después de que ocurra un evento de
          incumplimiento que continúe, podemos, sin perjuicio de cualquier otro
          derecho o recurso otorgado por la Ley Aplicable:
        </p>
        <p></p>
        <p>
          13.2.1. rescindir estos Términos y Condiciones de acuerdo con la
          Cláusula 14 del presente documento; 13.2.2. declarar que el Préstamo
          (incluidos todos los intereses devengados, Las tarifas de transacción,
          los impuestos y todos los demás montos pendientes según estos Términos
          y Condiciones) se vence y paga inmediatamente, por lo que se vencen y
          se pagan inmediatamente; 13.2.3. proporcionar información sobre el
          caso de incumplimiento a las oficinas de la Central de Riesgos ; e
          13.2.4. imponer cargos por pagos atrasados a la tasa que se muestra en
          la Aplicación en relación con su incumplimiento de pago.
        </p>
        <p></p>
        <p>14. PLAZO Y RESCISIÓN</p>
        <p></p>
        <p>
          14.1. Estos Términos y Condiciones continuarán hasta su finalización
          de acuerdo con sus términos.
        </p>
        <p></p>
        <p>
          14.2. Podemos rescindir estos Términos y Condiciones y / o suspender o
          rescindir su uso del Sistema, el Servicio y su Cuenta en su totalidad
          o en parte:
        </p>
        <p></p>
        <p>
          14.2.1. en cualquier momento por cualquier motivo mediante
          notificación a El Usuario; 14.2.2. inmediatamente, con o sin previo
          aviso, si El Usuario incumple cualquiera de los términos de estos
          Términos y Condiciones, sin perjuicio de nuestros otros derechos y
          recursos; 14.2.3. si su Cuenta o acuerdo con un Operador de Red Móvil
          o Entidad Bancaria se rescinde por cualquier motivo; 14.2.4. cuando
          dicha suspensión o terminación sea necesaria como consecuencia de
          problemas técnicos o por razones de seguridad; para facilitar la
          actualización o actualización de los contenidos o la funcionalidad del
          Servicio de tiempo en tiempo; donde su Cuenta se vuelve inactiva o
          inactiva; 14.2.5. si se nos exige o se nos solicita que cumplamos con
          una orden o instrucción o una recomendación del gobierno, tribunal,
          regulador u otra autoridad competente ；o 14.2.6. si decidimos
          suspender o suspender la prestación del Servicio por razones
          comerciales o por cualquier otro motivo que podamos determinar a
          nuestra absoluta discreción;
        </p>
        <p></p>
        <p>
          14.3. Al término o vencimiento de estos Términos y Condiciones por
          cualquier motivo, El Usuario deberá:
        </p>
        <p></p>
        <p>
          14.3.1. puntualmente (y en cualquier caso dentro de los tres días),
          pague el principal, los intereses, los honorarios de transacción o los
          impuestos que se nos adeuden (que serán pagaderos inmediatamente y
          pagaderos al finalizar); y 14.3.2. eliminar inmediatamente y eliminar
          completamente la aplicación de su Dispositivo Móvil. 14.4. Sin
          embargo, la rescisión no afectará los derechos y responsabilidades
          acumulados de ninguna de las partes. 14.5. Las partes no tendrán más
          obligaciones o derechos en virtud de estos Términos y Condiciones
          después de la terminación de los Términos y Condiciones, sin perjuicio
          de las obligaciones o derechos que se hayan acumulado a cualquiera de
          las partes en el momento de su terminación, salvo las disposiciones de
          las Cláusulas 2 3, 8, 11, 13, 14, 15 y 16 de estos Términos y
          Condiciones y cualquier otra cláusula que expresamente o por su
          naturaleza tenga la intención de sobrevivir, continuará teniendo
          efecto después del final de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>15. INDEMNIZACIONES Y EXCLUSIÓN DE RESPONSABILIDAD</p>
        <p></p>
        <p>Indemnizaciones</p>
        <p></p>
        <p>
          15.1. Deberá defender, indemnizar y eximir de responsabilidad a
          WePeso, a nuestros licenciantes y a los Afiliados de cada parte y sus
          respectivos funcionarios, directores, miembros, empleados y agentes de
          y contra todos y cada uno de los reclamos, costos, daños, pérdidas,
          responsabilidades y gastos (incluidos los abogados ‘honorarios y
          costos) que surjan de o en relación con:
        </p>
        <p></p>
        <p>15.2.</p>
        <p></p>
        <p>
          15.2.1. su incumplimiento de cualquier término de estos Términos y
          Condiciones o cualquier Ley aplicable; y 15.2.2. Su uso del Sistema y
          / o el Servicio, que incluye: 15.2.2.1. cualquier reclamo de terceros
          que surja de su uso del Sistema y / o el Servicio; 15.2.2.2. cualquier
          pérdida o daño que pueda derivarse de su uso, mal uso, abuso o
          posesión de cualquier software de terceros, incluidos, entre otros,
          cualquier sistema operativo, software de navegador o cualquier otro
          paquete o programa de software; 15.2.2.3. cualquier acceso no
          autorizado a su cuenta o cualquier violación de la seguridad o
          cualquier destrucción o acceso a sus datos o cualquier destrucción o
          robo o daño a su Dispositivo Móvil; y 15.2.2.4. cualquier pérdida o
          daño ocasionado por el incumplimiento por su parte de estos Términos y
          Condiciones y/o al proporcionar Información Personal incorrecta
          15.2.2.5. cualquier pérdida o daño ocasionado por la falla o falta de
          disponibilidad de instalaciones o sistemas de terceros o la
          incapacidad de un tercero parte para procesar una transacción o
          cualquier pérdida en la que podamos incurrir como consecuencia de
          cualquier incumplimiento de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>Exclusión de responsabilidad</p>
        <p></p>
        <p>
          15.3. No seremos responsables de ninguna pérdida sufrida por El
          Usuario en caso de que el Servicio sea interferido o no esté
          disponible debido a la falla de su Dispositivo Móvil, o cualquier otra
          circunstancia que no esté bajo nuestro control, incluyendo, entre
          otros, Fuerza mayor o error, interrupción, demora o falta de
          disponibilidad del Sistema, falla del equipo de acción terrorista o de
          cualquier enemigo, pérdida de potencia, condiciones climáticas o
          atmosféricas adversas y falla de cualquier sistema de
          telecomunicaciones público o privado.
        </p>
        <p></p>
        <p>
          15.4. El Usuario reconoce que la Aplicación no ha sido desarrollada
          para cumplir con sus requisitos individuales y que, por lo tanto, es
          su responsabilidad asegurarse de que las instalaciones y funciones de
          la Aplicación descritas cumplan con sus requisitos.
        </p>
        <p></p>
        <p>
          15.5. Sólo suministramos la Aplicación para uso privado. El Usuario
          acepta no utilizar la Aplicación para fines comerciales, comerciales o
          de reventa, y no tenemos ninguna responsabilidad ante El Usuario por
          cualquier pérdida de ganancias, pérdida de negocios, interrupción de
          negocios o pérdida de oportunidades comerciales.
        </p>
        <p></p>
        <p>
          15.6. No seremos responsables de ninguna pérdida o daño sufrido por El
          Usuario como resultado de o en relación con:
        </p>
        <p></p>
        <p>
          15.6.1. cualquier defecto o falla en la Aplicación o en cualquier
          Servicio que resulte de que El Usuario haya alterado o modificado la
          Aplicación; 15.6.2. cualquier defecto o falla en la Aplicación que
          resulte de que El Usuario haya utilizado la Aplicación en violación de
          estos Términos y Condiciones; 15.6.3. su incumplimiento de la Cláusula
          6 del presente documento; 15.6.4. indisponibilidad de fondos
          suficientes en su Cuenta Bancaria; 15.6.5. falla, mal funcionamiento,
          interrupción o indisponibilidad del sistema, su Dispositivo Móvil, la
          red o un sistema de su Cuenta Bancaria; el dinero en su Cuenta está
          sujeto a un proceso legal u otro gravamen que restringe los pagos o
          transferencias de los mismos; Su falta de dar instrucciones apropiadas
          o completas para pagos o transferencias relacionadas con su Cuenta;
          15.6.6. cualquier uso fraudulento o ilegal del Servicio, el Sistema y
          / o su Dispositivo Móvil; o 15.6.7. su incumplimiento de estos
          Términos y Condiciones y cualquier documento o Información
          Confidencial proporcionada por WePeso sobre el uso del Sistema y el
          Servicio.
        </p>
        <p></p>
        <p>
          15.7. Bajo ninguna circunstancia seremos responsables ante El Usuario
          por cualquier pérdida de ganancias o ahorros anticipados o por
          cualquier pérdida o daño indirecto o consecuente de cualquier tipo,
          sea cual sea su causa, que surja o esté relacionado con el Servicio,
          incluso cuando exista la posibilidad de tal pérdida o se nos notifica
          el daño.
        </p>
        <p></p>
        <p>
          15.8. En la máxima medida permitida por la Ley Aplicable, y a menos
          que se especifique lo contrario en estos Términos y Condiciones, en
          ningún caso nuestra responsabilidad total máxima que surja en relación
          con la Aplicación, el Sistema, el Servicio y / o estos Términos y
          Condiciones, ya sea en el contrato, agravio, incumplimiento del deber
          legal o de otro modo, exceder los honorarios de transacción pagados
          por El Usuario a WePeso en relación con el primer evento que da lugar
          a un reclamo en virtud de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          15.9. A menos que se especifique lo contrario en estos Términos y
          Condiciones, cualquier reclamo que tenga contra WePeso en relación con
          la Aplicación, el Sistema, el Servicio o estos Términos y Condiciones
          debe notificarnos dentro de los seis (6) meses posteriores a los
          eventos que dieron lugar a dicho reclamo, en caso de que (en la medida
          máxima permitida por la Ley Aplicable) perderá los derechos y recursos
          que tenga con respecto a dicho reclamo.
        </p>
        <p></p>
        <p>15.10. No asumimos ninguna responsabilidad hacia El Usuario por:</p>
        <p></p>
        <p>
          15.10.1. mal funcionamiento en las instalaciones de comunicaciones que
          no pueden considerarse razonablemente bajo nuestro control y que
          pueden afectar la precisión o la puntualidad de los mensajes que envía
          o el material al que accede a través de la Aplicación; 15.10.2.
          cualquier pérdida o demora en la transmisión de mensajes o material a
          los que El Usuario acceda que surja del uso de cualquier proveedor de
          servicios de acceso a Internet o proveedor de servicios de red móvil o
          causado por cualquier navegador u otro software que no esté bajo
          nuestro control; 15.10.3. virus que pueden infectar su Dispositivo
          Móvil u otra propiedad a causa de su acceso o uso de la Aplicación/
          Sitio Web o su acceso a cualquier material en la Aplicación / Sitio
          Web; 15.10.4. cualquier uso no autorizado o intercepción de cualquier
          mensaje o Información Personal antes de que llegue a la Aplicación o
          Nuestros servidores desde la Aplicación; 15.10.5. cualquier uso no
          autorizado o acceso a datos relacionados con El Usuario o sus
          transacciones que tengamos WePeso (a menos que dicho uso o acceso sea
          causado por nuestra negligencia, fraude o incumplimiento de las Leyes
          Vigentes relacionadas con la protección de sus datos), en la medida
          permitida por la Ley Aplicable; 15.10.6. cualquier contenido
          proporcionado por terceros.
        </p>
        <p></p>
        <p>16. SITIO DE TERCEROS</p>
        <p></p>
        <p>
          16.1. Podemos establecer en el Sitio Web o la Aplicación enlaces y
          punteros a otros sitios web o aplicaciones móviles que son operados y
          mantenidos por terceros (“Sitios o Aplicaciones de Terceros”). Estos
          enlaces se proporcionan únicamente como indicadores de información
          sobre temas que pueden serle útiles. Sin embargo, el establecimiento
          de cualquier enlace a Sitios o Aplicaciones de terceros no es una
          recomendación o aprobación por nuestra parte de ningún producto,
          servicio, información, bienes, ideas u opiniones que puedan
          encontrarse en Sitios o Aplicaciones de terceros.
        </p>
        <p></p>
        <p>
          16.2. No realizamos ninguna garantía, expresa o implícita, con
          respecto al contenido de los sitios o Aplicaciones de Terceros,
          incluida la precisión, integridad, confiabilidad o idoneidad de los
          mismos para cualquier propósito en particular. No garantizamos que
          ningún Sitio o Aplicación de Terceros esté libre de cualquier reclamo
          de derechos de autor, marca registrada u otra infracción. No
          garantizamos que ningún Sitio o Aplicación de Terceros esté libre de
          virus u otra contaminación.
        </p>
        <p></p>
        <p>
          16.3. El Usuario comprende que los Sitios y Aplicaciones de Terceros
          pueden tener una política de privacidad diferente a la nuestra y que
          los sitios y aplicaciones de terceros pueden proporcionar menos
          seguridad que la nuestra. La decisión de acceder a un Sitio o
          Aplicación de Terceros, o comprar o utilizar cualquiera de los
          productos o servicios anunciados o proporcionados en un Sitio o
          Aplicación de terceros es suya, a su exclusivo criterio.
        </p>
        <p></p>
        <p>
          17. CONSENTIMIENTO PARA RECIBIR COMUNICACIONES DE MARKETING DIRECTO
        </p>
        <p></p>
        <p>
          Al utilizar los Servicios, El Usuario acepta recibir nuestras
          comunicaciones de marketing directo. Si no desea recibir nuestras
          comunicaciones de marketing, puede expresar su elección donde se
          indique en la comunicación correspondiente.
        </p>
        <p></p>
        <p>18. PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>
          El software asociado al Sitio Web y/o Aplicación/App y/o Sistema, así
          como todo el contenido, Información Confidencial y/o material
          disponible en el mismo, son propiedad WePeso y/o de sus servidores
          externos; por tanto, están protegidos por las Leyes Vigentes y
          Tratados Internacional en materia de derechos de autor, marcas,
          patentes, modelos y diseños industriales aplicables en el Chile, sin
          importar que se encuentren o no registrados. Quedan reservados por
          WePeso y/o de sus servidores externos; todos los derechos no otorgados
          expresamente en estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          Se encuentra terminantemente prohibido cualquier tipo de copia,
          distribución, transmisión, retransmisión, publicación, impresión,
          difusión y/o explotación comercial del material y/o contenido puesto a
          disposición a través de la Plataforma, sin el previo consentimiento
          expreso y por escrito de WePeso. Cualquier violación a lo dispuesto en
          éste apartado constituirá una infracción a los derechos de propiedad
          intelectual y sujetará al infractor a las sanciones administrativas,
          civiles y será materia para la presentación de la querella, la
          denuncia o la reclamación ante las autoridades competentes, dentro de
          la cual se proporcionará la información necesaria para la
          investigación y persecución del ilícito del que se trate, obligándose
          el infractor a indemnizar y a liberar de toda responsabilidad a WePeso
          por cualquier reclamación que se pudiere instaurar en su contra
          derivada de cualquier incumplimiento a lo dispuesto en este apartado.
        </p>
        <p></p>
        <p>19. COOKIES</p>
        <p></p>
        <p>
          Al ingresar al Sitio Web [*] así como al hacer uso de nuestra
          Aplicación se utilizan elementos tecnológicos conocidos como Cookies.
          Las Cookies son pequeños archivos almacenados en su computadora o
          Dispositivo Móvil. Utilizamos Cookies para rastrear la actividad del
          Usuario para mejorar la interfaz y la experiencia del Usuario. La
          mayoría de los Dispositivos Móviles y navegadores de internet admiten
          el uso de Cookies; pero puede ajustar la configuración de su
          Dispositivo Móvil o navegador de Internet para rechazar varios tipos
          de ciertas Cookies o ciertas Cookies especificas. Su Dispositivo Móvil
          y / o navegador también le permitirán eliminar en cualquier momento
          las Cookies que se hayan almacenado previamente. Sin embargo, hacerlo
          puede afectar las funcionalidades disponibles en la Aplicación o en
          nuestro Sitio web.
        </p>
        <p></p>
        <p>20. VALIDEZ Y VIGENCIA DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          En caso que cualquier disposición del presente documento resulte
          inválida o nula por cualquier motivo, dicha disposición será separada
          y no afectará la validez y cumplimiento de las disposiciones
          restantes. Asimismo, la aplicación de dicha disposición a personas o
          situaciones que no sean aquéllas respecto de las cuales tal
          disposición es considerada inválida o nula, no se verán afectados y
          serán válidas y exigible en la mayor medida permitida por las Leyes
          Vigentes y en la medida que dicha interpretación sea consistente con
          la voluntad de las partes de conformidad con los términos y
          condiciones de la disposición como fuera originalmente redactada.
        </p>
        <p></p>
        <p>21. JURISDICCIÓN Y LEGISLACIÓN APLICABLE</p>
        <p></p>
        <p>
          Para todo lo relativo a la interpretación, cumplimiento y ejecución de
          las obligaciones derivadas de los presentes Términos y Condiciones,
          las Partes se someten a las Leyes Vigentes y a la jurisdicción de la
          República de Santiago. Asimismo, para la resolución de cualquier
          controversia que se origine con motivo del mismo, las Partes se
          someten expresamente a la jurisdicción de los tribunales competentes
          en la ciudad de Santiago, renunciando desde ahora a cualquier otra
          jurisdicción que pudiera corresponderles en razón de sus domicilios
          presentes o futuros o por la ubicación de sus bienes.
        </p>
        <p></p>
        <p>22. PROTECCIÓN DE DATOS PERSONALES Y PRIVACIDAD</p>
        <p></p>
        <p>
          De conformidad con la Ley de Protección de Datos Personales, aprobada
          por Ley No. 29733, WePeso implementará todas las medidas posibles para
          mantener la confidencialidad y la seguridad de la Información Personal
          de los Usuarios, pero no responderá por los daños, pérdidas o
          perjuicios que pudieran derivarse de la violación de esas medidas por
          parte de terceros que utilicen las redes públicas o internet,
          alterando los sistemas de seguridad para obtener acceso a la
          Información Personal registrada en WePeso.
        </p>
        <p></p>
        <p>23. GENERAL</p>
        <p></p>
        <p>
          23.1. No seremos responsables por demoras o fallas en el desempeño
          como resultado de causas que escapen a nuestro control razonable.
        </p>
        <p></p>
        <p>
          23.2. No deberá divulgar en ningún momento a ninguna persona ninguna
          Información confidencial relacionada con el negocio, asuntos,
          clientes, clientes o proveedores de WePeso o cualquiera de nuestros
          Afiliados.
        </p>
        <p></p>
        <p>
          23.3. El Usuario comprende y acepta que podemos asignar, transferir
          todos o parte de los derechos del acreedor del Préstamo a nuestro
          exclusivo criterio de tiempo en tiempo sin notificárselo
          (“Transferencia”). La transferencia anterior no afectará la obligación
          del Usuario bajo estos Términos. Deberá realizar el pago de acuerdo
          con las instrucciones que mostramos en la Aplicación.
        </p>
        <p></p>
        <p>
          23.4. Podemos modificar estos Términos y Condiciones a nuestro
          exclusivo criterio de tiempo en tiempo. Haremos todos nuestros
          esfuerzos razonables para notificarle sobre cualquier cambio
          importante en los Términos y Condiciones; sin embargo, acepta que es
          su responsabilidad revisar los Términos y Condiciones regularmente y
          que su uso continuo del Sistema y el Servicio constituirá su
          aceptación de cualquiera de las enmiendas.
        </p>
        <p></p>
        <p>
          23.5. Los derechos de cada parte en virtud de estos Términos y
          Condiciones pueden ejercerse con la frecuencia que sea necesaria, son
          acumulativos y no exclusivos de los derechos o recursos previstos por
          la ley y sólo pueden renunciarse por escrito y específicamente. La
          demora en el ejercicio o no ejercicio de cualquier derecho no es una
          renuncia a ese derecho.
        </p>
        <p></p>
        <p>
          23.6. Estos Términos y Condiciones constituyen el acuerdo completo y
          la comprensión de las partes relacionadas con el tema de estos
          Términos y Condiciones y reemplaza cualquier acuerdo o entendimiento
          previo entre las partes en relación con dicho tema. Las partes también
          excluyen todos los términos implícitos de hecho. Al suscribir estos
          Términos y Condiciones, las partes no se han basado en ninguna
          declaración, representación, garantía, comprensión, compromiso,
          promesa o garantía de ninguna persona que no sea la expresamente
          establecida en estos Términos y Condiciones. Cada parte renuncia de
          manera irrevocable e incondicional a todos los reclamos, derechos y
          recursos que, de no ser por esta Cláusula, podrían haber tenido en
          relación con cualquiera de los anteriores. Nada en estos Términos y
          Condiciones excluye la responsabilidad por fraude o cualquier otra
          responsabilidad que no pueda ser limitada o excluida bajo la Ley
          Aplicable.
        </p>
        <p></p>
        <p>
          23.7. No puede asignar, sublicenciar, transferir, subcontratar o
          disponer de ninguno de sus derechos u obligaciones, bajo estos
          Términos y Condiciones sin nuestro consentimiento previo por escrito.
          Podemos en cualquier momento asignar, sub licenciar, transferir,
          subcontratar o disponer de nuestros derechos u obligaciones bajo estos
          Términos y Condiciones sin previo aviso o consentimiento (salvo en la
          medida requerida por la Ley Aplicable).
        </p>
        <p></p>
        <p>
          23.8. Si cualquier tribunal o autoridad relevante determina que alguna
          parte de estos Términos y Condiciones es ilegal, inválida o
          inaplicable según la Ley aplicable, las partes restantes de estos
          Términos y Condiciones permanecerán en pleno vigor y efecto y la parte
          relevante será reemplazada por un disposición que es legal, válida y
          aplicable y que tiene, en la mayor medida posible, un efecto
          equivalente a la parte sustituida de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          23.9. Una persona que no sea parte en estos Términos y Condiciones no
          tiene derecho a confiar o hacer cumplir ningún término de estos
          Términos y Condiciones.
        </p>
        <p></p>
        <p>24. NOTIFICACIONES Y AVISOS</p>
        <p></p>
        <p>
          Las notificaciones y/o avisos que se hagan las Partes se enviarán por
          correo electrónico a las direcciones que se establecen más abajo, ya
          sea para fines relacionados a presentar alguna queja, sugerencia o
          comentario en relación con el uso de la plataforma o a los presentes
          Términos y Condiciones, incluyendo sus posibles modificatorias. Todas
          las notificaciones, avisos o comunicaciones que las partes se dirijan
          en términos de esta cláusula se entenderán recibidas al día inmediato
          siguiente a la fecha de su entrega. Las partes señalan como sus
          direcciones de correo electrónico para efectos de lo anterior, las
          siguientes:
        </p>
        <p></p>
        <p>● WePeso: hola@we-peso.com</p>
        <p></p>
        <p>● El Usuario: el registrado en el Sitio WEb y/o Aplicación.</p>
        <p></p>
        <p>25. CONSENTIMIENTO</p>
        <p></p>
        <p>
          El Usuario en este acto, acepta que ha leído detenidamente y comprende
          el contenido y alcance de todas y cada una de las disposiciones
          contenidas en los presentes Términos y Condiciones, en la Política de
          Privacidad y demás documentos publicados por WePeso.
        </p>
        <p></p>
        <p>Fecha de última actualización: 28 de marzo de 2024.</p>
      </div>
    ) : (
      <div />
    );
  }
}
export default ServiceAgreement;
