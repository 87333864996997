import React from "react";
import QueueAnim from "rc-queue-anim";
import { Row } from "antd";
import { page2 } from "./data";
import { Router, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();
export default function Page2(props) {
  const renderCard = (data) => {
    const cardHtml = data.map((card, i) => (
      <div
        className={props.isMobile ? "one-mobile-faq" : "one-faq"}
        key={i.toString()}
      >
        <img src={card.img} className="cardImg" alt="card" />
        <div className="cardTitle">{card.title}</div>
        <div className="cardDetail">
          {card.descriptions.map((item, idx) => {
            return <p key={idx}>{item}</p>;
          })}
        </div>
      </div>
    ));
    return cardHtml;
  };

  const children = renderCard(page2[props.contryKey]);
  const titleStr = {
    ph: "WePeso’s team has compiled a list of common misgivings that they hope will help you.",
    cl: "El equipo de WePeso ha compilado una lista de dudas comunes que esperan le ayuden.",
  };

  if (props.isMobile) {
    return props.contryKey === "cl" ? (
      <section id="FAQ" className="page-mobile-wrapper">
        <div className="pm2-comtent">
          <div className="pm2-title">{"FAQ"}</div>
          <p className="pm2-subtitle">{titleStr[props.contryKey]}</p>
          <QueueAnim type="bottom" className="pm2-faqs" delay={100}>
            {children}
          </QueueAnim>
          {/* 移动端的follow us and regulations */}
          <div className="follow-regul">
            <div className="one-item regul">
              <div className="item-title">Reglamentos</div>
              <Router history={browserHistory}>
                <Link to="/cusPrivacy?cty=cl" target="_blank">
                  《 Acuerdo de Privacidad 》
                </Link>
                <Link to="/cusService?cty=cl" target="_blank">
                  《 Acuerdo de Servicio 》
                </Link>
              </Router>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <section id="FAQ" className="page-mobile-wrapper">
        <div className="pm2-comtent">
          <div className="pm2-title">{"FAQ"}</div>
          <p className="pm2-subtitle">{titleStr[props.contryKey]}</p>
          <QueueAnim type="bottom" className="pm2-faqs" delay={100}>
            {children}
          </QueueAnim>
          {/* 移动端的follow us and regulations */}
          <div className="follow-regul">
            <div className="one-item regul">
              <div className="item-title">Regulations</div>
              <Router history={browserHistory}>
                <Link to="/cusCredit" target="_blank">
                  《 WePeso Loan Policy》
                </Link>
                <Link to="/cusPrivacy?cty=ph" target="_blank">
                  《Customer Privacy Clause》
                </Link>
                <Link to="/cusService?cty=ph" target="_blank">
                  《Customer Service Clause》
                </Link>
              </Router>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="FAQ" className="page-wrapper page2">
      <div className="page2-content">
        <p className="page2-title">{"FAQ"}</p>
        <p className="page2-subtitle">{titleStr[props.contryKey]}</p>
        <QueueAnim
          component={Row}
          type="bottom"
          className="page2-faqs"
          delay={500}
        >
          {children}
        </QueueAnim>
      </div>
    </section>
  );
}
